import * as t from "io-ts";
import { createConsolidatedInvoiceTypes, deferredInvoicesFolderT } from "dora-contracts";

export { createDeferredInvoiceT } from "dora-contracts";

export const { deferredInvoiceT, deferredInvoiceLineT, getClientsResponseT, getDeferredInvoicesResponseT } =
  createConsolidatedInvoiceTypes({
    decimal: t.string,
  });

export type DeferredInvoice = t.TypeOf<typeof deferredInvoiceT>;
export type DeferredInvoicesFolder = t.TypeOf<typeof deferredInvoicesFolderT>
export type DeferredInvoiceLine = t.TypeOf<typeof deferredInvoiceLineT>;
export type CargoStop = DeferredInvoice["cargo"]["stop"];
export type UninvoicedClients = t.TypeOf<typeof getClientsResponseT>;
