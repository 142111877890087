import "./mapsjs-ui.css";
import { useEffect, useRef } from "react";
import { useCreateMap } from "../../../integration/useCreateMap";

// Get an instance of the routing service version 8:
// const routingService = platform.getRoutingService(undefined, 8);

interface InnerMapProps {
  lineStrings?: H.geo.MultiLineString;
  mapObjects?: H.map.Object[] | null;
  markers?: H.map.Marker[];
  customizeBehaviour?: (behavour: H.mapevents.Behavior) => void;
}

export const InnerMap = (props: InnerMapProps) => {
  const mapRef = useRef<HTMLDivElement>(null);
  const hMap = useRef<H.Map>();
  const ui = useRef<H.ui.UI | null>();
  const behaviour = useRef<H.mapevents.Behavior>();
  const createMap = useCreateMap(false);
  const { customizeBehaviour } = props;
  // Setup map
  useEffect(() => {
    // Get the default map types from the platform object

    // Instantiate the map
    const mapDiv = mapRef.current;
    const { map, mapUi } = createMap(mapDiv!);
    hMap.current = map;
    // Enable the event system on the map instance
    const mapEvents = new H.mapevents.MapEvents(map);
    // Instantiate the default behavior, providing the mapEvents object
    behaviour.current = new H.mapevents.Behavior(mapEvents);
    // Create the default UI:
    ui.current = mapUi;

    // Allow caller to customize behaviour
    // The default is to disable mouse wheel, probably because that was the
    // best UX where the map was first used.
    // This is probably not the best sensible default right now.
    if (customizeBehaviour) {
      customizeBehaviour(behaviour.current);
    }
    // else {
    // disable wheel zoom behavior
    // behaviour.current.disable(H.mapevents.Behavior.WHEELZOOM);
    // }

    return () => {
      // Remove old map if this rerenders (this happends on save file while developing)
      while (mapDiv?.firstChild) {
        mapDiv.removeChild(mapDiv.firstChild);
      }
    };
  }, [createMap, customizeBehaviour]);

  useEffect(() => {
    const hasData = !!props.lineStrings || !!props.mapObjects;
    if (!hMap.current || !hasData) {
      return;
    }
    // Render route on the map
    // Create a polyline to display the route:
    let routeOutline: H.map.Object;
    if (props.lineStrings) {
      routeOutline = new H.map.Polyline(props.lineStrings, {
        style: {
          lineWidth: 8,
          strokeColor: "rgba(0, 128, 255, 0.7)",
          //lineTailCap: "arrow-tail",
          //lineHeadCap: "arrow-head",
        },
      } as any);

      hMap.current.addObjects([routeOutline]);
    }
    props.markers && hMap.current.addObjects(props.markers);
    props.mapObjects && hMap.current.addObjects(props.mapObjects);

    // Zoom to polyline
    if (props.lineStrings) {
      const bounds = props.lineStrings.getBoundingBox();
      if (bounds) {
        hMap.current.getViewModel().setLookAtData({ bounds });
      }
    } else if (props.mapObjects) {
      var boundingBox: H.geo.Rect | undefined;
      for (const m of props.mapObjects) {
        if (m instanceof H.map.GeoShape) {
          const newBox = m.getBoundingBox();
          boundingBox = boundingBox ? boundingBox.mergeRect(newBox) : newBox;
        }
      }
      hMap.current.getViewModel().setLookAtData({
        bounds: boundingBox,
      });
    }
    return () => {
      if (!hMap.current) {
        return;
      }
      props.markers && hMap.current.removeObjects(props.markers);
      if (routeOutline) {
        hMap.current.removeObjects([routeOutline]);
      }
      if (props.mapObjects) {
        hMap.current.removeObjects(props.mapObjects);
      }
    };
  }, [props.markers, props.lineStrings, props.mapObjects]);

  useEffect(() => {
    const resize = () => {
      hMap.current?.getViewPort().resize();
    };

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  return <div id="mapContainer" ref={mapRef} />;
};
