import { Button, TextField } from "@mui/material";
import SavedLocation, { SavedLocationType } from "./SavedLocation";
import { BackButton } from "../../dispatching-planning/common/back-button";
import { CloseButton } from "../../dispatching-planning/common/close-button";
import { useState } from "react";
import "./AddEditSavedLocation.scss";
import NewPlaceSearch, {
  AddressChange,
} from "../add-edit-cargo/NewPlaceSearch";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { ConfirmDeleteLocationDialog } from "./ConfirmDeleteLocationDialog";
import SavedLocationTypeSelectionOption from "./SavedLocationTypeSelectionOption";
import { useTranslation } from "react-i18next";
import { useActions } from "../../../hooks/useActions";
import {
  loadSavedLocations,
  createSavedLocation,
  updateSavedLocation,
  deleteSavedLocation,
} from "../../../ducks/data/saved-locations";
import { UFDoraCheckbox } from "../../Toolkit/DoraCheckbox";
import { useFeature } from "../../../hooks";
import { UFDoraTimeRangePicker } from "../../Toolkit/DoraTimeRangePicker";
import { UFDoraTextInput } from "../../Toolkit/DoraTextInput";
import { timeRangeMin5MinutesErr } from "../CreateEditShipmentDialog/create-edit-shipment-validation-schema";

const blankLocation: SavedLocation = {
  type: "FAVORITE",
  visibleToDrivers: false,
  customName: "",
  place: {
    address: "",
    city: "",
    country: "",
    placeName: "",
    postcode: "",
    coord: { lon: 200, lat: 200 },
  },
  openingTime: "",
  contactName: "",
  contactPhoneNumber: "",
};

const validationSchema = yup.object({
  customName: yup.string().optional(),
  visibleToDrivers: yup.boolean().optional(),
  place: yup.object({
    placeName: yup.string().optional(),
    address: yup.string().optional(),
    city: yup.string().optional(),
    coord: yup.object({
      lon: yup.number().min(-180).max(180),
      lat: yup.number().min(-180).max(180),
    }),
  }),
  openingTime: yup
    .string()
    .transform((_, value) => (value === " - " ? "" : value))
    .test("invalid", (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeEnd && !timeStart) {
        return false;
      }
      if (timeEnd && timeStart) {
        if (timeEnd < timeStart) {
          return false;
        }
      }
      return true;
    })
    .test(timeRangeMin5MinutesErr, (value) => {
      if (!value) {
        return true;
      }
      const [timeStart, timeEnd] = value.split(" - ");
      if (timeStart && timeEnd) {
        const [startHour, startMinute] = timeStart.split(":");
        const [endHour, endMinute] = timeEnd.split(":");
        const startMins = Number(startHour) * 60 + Number(startMinute);
        const endMins = Number(endHour) * 60 + Number(endMinute);
        return endMins - startMins >= 5;
      }
      return true;
    }),
  contactName: yup.string().optional(),
  contactPhoneNumber: yup.string().optional(),
});

const AddEditSavedLocation = ({
  addTerminalOnly,
  location,
  type,
  onClose,
  onBack,
}: {
  addTerminalOnly?: boolean;
  location: SavedLocation | "NEW";
  type: SavedLocationType;
  onClose: () => void;
  onBack: () => void;
}) => {
  const { t } = useTranslation(["modals", "components", "shipmentModal"]);
  const [placeSelected, setPlaceSelected] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] =
    useState(false);
  const actions = useActions({
    loadSavedLocations,
    createSavedLocation,
    updateSavedLocation,
    deleteSavedLocation,
  });
  const savedLocationsVisibleToDriversEnabled = useFeature(
    "saved-locations-visible-to-drivers"
  );
  const liteVersion = useFeature("lite-version");
  const title = addTerminalOnly
    ? t("modals:manageSavedLocations.addTerminal")
    : location === "NEW"
      ? t("modals:manageSavedLocations.addPlace")
      : t("modals:manageSavedLocations.editPlace");
  const buttonTitle =
    location === "NEW"
      ? t("components:buttonLabels.add")
      : t("components:buttonLabels.save");
  const initialSelectionType = addTerminalOnly
    ? "TERMINAL"
    : location === "NEW"
      ? type
      : location.type;
  blankLocation.type = initialSelectionType;
  const [selectionType, setSelectionType] =
    useState<SavedLocationType>(initialSelectionType);

  const onSelectionTypeClick = (selectionType: SavedLocationType) => {
    setSelectionType(selectionType);
    setValue("type", selectionType, { shouldValidate: true });
  };

  const formMethods = useForm<SavedLocation>({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues:
      location === "NEW"
        ? blankLocation
        : {
            visibleToDrivers: location.visibleToDrivers,
            customName: location.customName,
            type: location.type,
            id: location.id,
            place: {
              placeName: location.place.placeName as string,
              address: location.place.address as string,
              city: location.place.city as string,
              coord: {
                lon: location.place.coord.lon as number,
                lat: location.place.coord.lat as number,
              },
              country: location.place.country as string,
              postcode: location.place.postcode as string,
              googlePlaceId: location.place.googlePlaceId as string,
            },
            openingTime: location.openingTime,
            contactName: location.contactName,
            contactPhoneNumber: location.contactPhoneNumber,
          },
  });

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isValid },
  } = formMethods;

  const onDeleteClick = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const onConfirmDeleteButtonClick = () => {
    actions.deleteSavedLocation(location as SavedLocation).then(() => {
      setIsDeleteConfirmationOpen(false);
      actions.loadSavedLocations();
      onBack();
    });
  };

  const onSaveClick = () => {
    handleSubmit(
      () => {
        const values = getValues();
        const action =
          location === "NEW"
            ? actions.createSavedLocation
            : actions.updateSavedLocation;
        if (values.customName && values.place) {
          values.place.placeName = values.customName;
        }

        action({
          ...values,
          ...(location !== "NEW" && { id: location.id }),
          customName: (values.customName ||
            values.place?.placeName ||
            values.place?.address) as string,
        }).then(() => {
          actions.loadSavedLocations();
          onBack();
        });
      },
      (err) => {
        console.error(err);
      }
    )();
  };

  const onPlaceSelected = (addressChange: AddressChange | null) => {
    if (addressChange) {
      setPlaceSelected(true);
      setValue(
        "place",
        {
          placeName: addressChange.placeName,
          address: addressChange.address,
          city: addressChange.city,
          coord: {
            lon: addressChange.coord.lon,
            lat: addressChange.coord.lat,
          },
          country: addressChange.country,
          postcode: addressChange.postcode,
          googlePlaceId: addressChange.googlePlaceId,
        },
        { shouldValidate: true }
      );
    } else {
      setPlaceSelected(false);
      setValue("place", blankLocation.place, { shouldValidate: true });
    }
  };

  return (
    <>
      {isDeleteConfirmationOpen && (
        <ConfirmDeleteLocationDialog
          locationName={(location as SavedLocation).customName}
          onContinueClick={onConfirmDeleteButtonClick}
          onCancelClick={() => setIsDeleteConfirmationOpen(false)}
        />
      )}
      <div className="favorites-terminals-dialog__container">
        <div className="favorites-terminals-dialog__title">
          <div className="favorites-dialog-title__left">
            {!addTerminalOnly && <BackButton onClick={onBack} />}
            <div className="add-edit-favorites-terminals__title">{title}</div>
          </div>
          <CloseButton onClick={onClose} />
        </div>
        <div className="add-edit-favorites-terminals__place-search">
          <NewPlaceSearch
            onPlaceSelected={onPlaceSelected}
            placeError={false}
          />
        </div>
        <hr className="favorites-terminals-dialog__hr" />
        {!addTerminalOnly && (
          <div
            className="add-edit-favorites-terminals__type-selections"
            role="radiogroup"
          >
            <SavedLocationTypeSelectionOption
              type="favorite"
              active={selectionType === "FAVORITE"}
              onClick={() => onSelectionTypeClick("FAVORITE")}
              hasCheckIcon
            />
            <SavedLocationTypeSelectionOption
              type="terminal"
              active={selectionType === "TERMINAL"}
              onClick={() => onSelectionTypeClick("TERMINAL")}
              hasCheckIcon
            />
          </div>
        )}
        <FormProvider {...formMethods}>
          <div className="add-edit-favorites-terminals__form-content">
            <div className="add-edit-favorites-terminals__form-row">
              <div style={{ flexGrow: 1 }}>
                <TextField
                  {...register("customName")}
                  InputLabelProps={{ shrink: true }}
                  label={t("modals:manageSavedLocations.placeLabel")}
                  disabled={!placeSelected}
                ></TextField>
                <div className="add-edit-favorites-terminals__input-subtext">
                  {t("modals:manageSavedLocations.placeNameSubtext")}
                </div>
              </div>
            </div>
            <div className="add-edit-favorites-terminals__form-row">
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("shipmentModal:inputs.searchPlaceAddressPlaceholder")}
                {...register("place.address")}
                disabled={!placeSelected}
              ></TextField>
            </div>
            <div className="add-edit-favorites-terminals__form-row">
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("shipmentModal:inputs.searchPlaceCityPlaceholder")}
                className={"add-edit-favorites-terminals__city-input"}
                {...register("place.city")}
                disabled={!placeSelected}
              ></TextField>
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("shipmentModal:inputs.searchPlaceZipPlaceholder")}
                className={"add-edit-favorites-terminals__postal-code-input"}
                {...register("place.postcode")}
                disabled={!placeSelected}
              ></TextField>
            </div>
            <div className="add-edit-favorites-terminals__form-row">
              <TextField
                InputLabelProps={{ shrink: true }}
                label={t("shipmentModal:inputs.searchPlaceCountryPlaceholder")}
                {...register("place.country")}
                disabled={!placeSelected}
              ></TextField>
            </div>
            <div className="add-edit-favorites-terminals__form-row gap-2 mt-1">
              <div className="flex-grow">
                <UFDoraTextInput
                  register={register("contactName")}
                  placeholder={t("shipmentModal:inputs.contactPlaceholder2")}
                />
              </div>
              <div className="flex-grow">
                <UFDoraTextInput
                  register={register("contactPhoneNumber")}
                  placeholder={t("shipmentModal:inputs.phoneLabel")}
                />
              </div>
            </div>
            <div className="add-edit-favorites-terminals__form-row gap-2 mt-2 mb-5">
              <div
                className="flex items-center justify-between"
                style={{ width: "50%" }}
              >
                <div style={{ color: "gray", margin: "0 4px" }}>
                  {t("shipmentModal:inputs.openingTimeLabel")}
                </div>
                <div style={{ maxWidth: 220, margin: "0 4px" }}>
                  <UFDoraTimeRangePicker
                    name={"openingTime"}
                    setValue={setValue}
                    getValues={getValues}
                    error={!!errors.openingTime}
                  />
                </div>
              </div>
            </div>
            {!liteVersion && savedLocationsVisibleToDriversEnabled && (
              <div className="add-edit-favorites-terminals__form-row mt-2">
                <div className="flex gap-2 items-center">
                  <UFDoraCheckbox register={register("visibleToDrivers")} />
                  {t("modals:manageSavedLocations.visibleToDrivers")}
                </div>
              </div>
            )}
          </div>
          <hr className="favorites-terminals-dialog__hr" />
          <div className="add-edit-favorites-terminals__buttons">
            <div>
              {location !== "NEW" && (
                <Button variant="text" color="error" onClick={onDeleteClick}>
                  {t("components:buttonLabels.delete")}
                </Button>
              )}
            </div>

            <div className="add-edit-favorites-terminals__right-buttons">
              <Button variant="outlined" color="primary" onClick={onClose}>
                {t("components:buttonLabels.cancel")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!isValid}
                onClick={onSaveClick}
              >
                {buttonTitle}
              </Button>
            </div>
          </div>
        </FormProvider>
      </div>
    </>
  );
};

export default AddEditSavedLocation;
