import "./RoutePlanning.css";
import React, { useCallback, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import RouteHeader from "../common/RouteHeader";
import Routing from "../map/Routing";
import { RouteDetails } from "./route-details";
import { useNavigate } from "react-router-dom";
import ArchiveRouteButton from "../common/archive-route-button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UnarchiveRouteButton from "../common/unarchive-route-button";
import { Tab, Box } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import RouteStops from "./RouteStops";
import RouteLog from "./RouteLog";
import { useAppDispatch } from "../../../redux-store";
import { getRouteStops, updateRouteName } from "../../../ducks/routes";
import { selectShowLargeMap } from "../../../ducks/app/route-info/routing/selectors";
import { setShowLargeMap } from "../../../ducks/app/route-info/routing";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import * as selectors from "../../../ducks/app/route-info/stops/selectors";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";
import RouteName from "../common/RouteName";
import { useFeature } from "../../../hooks";

const useOpenLargeMap = (routeId: string) => {
  const dispatch = useAppDispatch();
  const showLargeMap = useSelector(selectShowLargeMap);
  useEffect(() => {
    if (showLargeMap) {
      const newWindow = window.open(
        `/routes/${routeId}/map`,
        `route-${routeId}`
      );
      const interval =
        newWindow &&
        setInterval(() => {
          if (newWindow?.closed) {
            dispatch(setShowLargeMap(false));
          }
        }, 50);
      return () => {
        if (newWindow && !newWindow.closed) {
          newWindow.close();
        }
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [routeId, showLargeMap, dispatch]);
  return () => {
    dispatch(setShowLargeMap(true));
  };
};

const ArchiveRouteSection = ({ routeId }: { routeId: string }) => {
  const route = useSelector(selectRouteViewModel(routeId));
  const stops = useSelector(selectors.selectRouteStopsAndWaypoints);
  const shouldShowEndAndContinue = stops && stops.length && route?.trailerId;
  const liteVersion = useFeature("lite-version");

  return route ? (
    <Grid
      container
      direction="row"
      alignItems="center"
      justifyContent={shouldShowEndAndContinue ? "space-between" : "center"}
      gap={1}
      mt={0}
      mb={1}
    >
      {liteVersion ? (
        <ArchiveRouteButton routeId={routeId} />
      ) : route.archived ? (
        <UnarchiveRouteButton route_id={routeId} />
      ) : (
        <>
          {shouldShowEndAndContinue ? (
            <ArchiveRouteButton
              archiveAndCreateNew={!!route.trailerId}
              routeId={routeId}
            />
          ) : null}
          <ArchiveRouteButton routeId={routeId} />
        </>
      )}
    </Grid>
  ) : null;
};

export const RouteNameForRouteCard = ({
  routeId,
  iconToTheLeft,
}: {
  routeId: string;
  iconToTheLeft?: boolean;
}) => {
  const routeView = useSelector(selectRouteViewModel(routeId));
  const cargoOrderLength = routeView?.cargoOrder.length || 0;
  const dispatch = useAppDispatch();
  const liteVersion = useFeature("lite-version");

  const onSaveCustomName = useCallback(
    (typedCustomName: string) => {
      if (typedCustomName !== routeView?.name) {
        dispatch(
          updateRouteName({
            id: routeView!.id,
            route_name: typedCustomName || "",
          })
        );
      }
    },
    [dispatch, routeView]
  );

  return liteVersion ? (
    <></>
  ) : (
    <RouteName
      onSaveCustomName={onSaveCustomName}
      routeView={{
        ...routeView,
        hasCargos: cargoOrderLength > 0 || false,
        id: routeId,
        name: routeView?.name || null,
        waypointNote: routeView?.waypointNote || null,
        firstStop: routeView?.firstStop || null,
        lastStop: routeView?.lastStop || null,
      }}
      iconToTheLeft={iconToTheLeft}
    />
  );
};

export const RoutePlanning = React.memo((props: { routeId: string }) => {
  const { routeId } = props;
  const { t } = useTranslation(["dispatching"]);
  const [selectedTab, setSelectedTab] = useState("1");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const liteVersion = useFeature("lite-version");
  const showLargeMap = useSelector(selectShowLargeMap);

  useEffect(() => {
    dispatch(getRouteStops(routeId));
  }, [routeId, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setShowLargeMap(false));
    };
  }, [dispatch]);

  const closeRoute = () => {
    navigate("/dispatching");
  };

  const openLargeMap = useOpenLargeMap(routeId);

  return (
    <div className="route-planning">
      {!liteVersion && (
        <IconButton
          color="primary"
          aria-label={t("dispatching:routeCard.closeRouteButtonLabel")}
          onClick={closeRoute}
          sx={{ margin: 0, alignSelf: "end" }}
        >
          <CloseOutlinedIcon fontSize="small" color="action" />
        </IconButton>
      )}

      <div className="overflow-y-auto">
        <Grid container direction="column">
          {!showLargeMap && (
            <Grid item>
              <div style={{ position: "relative" }}>
                <IconButton
                  onClick={openLargeMap}
                  size="small"
                  disableRipple
                  disableFocusRipple
                  disableTouchRipple
                  sx={{
                    p: 0.88,
                    position: "absolute",
                    top: "1rem",
                    right: "16px",
                    mr: "8px",
                    backgroundColor: "white",
                    borderRadius: 1.2,
                    border: "solid 1px transparent",
                    boxShadow: 5,
                    zIndex: 1000,
                    ":hover": {
                      color: "rgba(0,0,0,0.7)",
                    },
                  }}
                >
                  <OpenInNewOutlinedIcon />
                </IconButton>
                <Box
                  sx={{
                    display: "flex",
                    height: "300px",
                    "&>div": { flexGrow: 1 },
                  }}
                >
                  <Routing routeId={routeId} />
                </Box>
              </div>
            </Grid>
          )}
          <TabContext value={selectedTab}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              {!liteVersion && (
                <TabList
                  onChange={(_e, v) => {
                    setSelectedTab(v);
                  }}
                  aria-label={t(
                    "dispatching:openRouteView.detailsOrLogTabLabel"
                  )}
                >
                  <Tab
                    label={t("dispatching:openRouteView.tabLabelInfo")}
                    value="1"
                  />
                  <Tab
                    label={t("dispatching:openRouteView.tabLabelLog")}
                    value="2"
                  />
                  {/* <Tab label="Item Three" value="3" /> */}
                </TabList>
              )}
            </Box>
            {liteVersion && (
              <div className="mt-2">
                <RouteHeader routeId={routeId} />
              </div>
            )}
            <TabPanel value="1" sx={{ p: 0 }}>
              <Grid
                item
                sx={{
                  paddingRight: 1,
                  paddingLeft: 1,
                }}
              >
                <RouteDetails routeId={routeId} />
                <hr />
              </Grid>
              <Grid
                item
                sx={{
                  paddingRight: 1,
                  paddingLeft: 1,
                }}
              >
                <RouteStops routeId={routeId} />
                <ArchiveRouteSection routeId={routeId} />
              </Grid>
            </TabPanel>
            <TabPanel value="2" sx={{ p: 0 }}>
              <RouteLog routeId={routeId} />
            </TabPanel>
          </TabContext>
        </Grid>
      </div>
    </div>
  );
});
