import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";

export const selectFinanceConfiguration = (state: RootState) => {
  return state.data.finance.configuration?.financeSystem || null;
};

export const selectIsFinanceSystemEconomic = createSelector(
  selectFinanceConfiguration,
  (configuration) => configuration === "E-CONOMIC"
);

/**
 * Select if units are enabled on invoices
 *
 * @returns null when configuration has not yet been loaded
 */
export const selectUnitsEnabledOnInvoices = (state: RootState) => {
  const configuration = state.data.finance.configuration;
  return configuration && configuration.unitsOnInvoices;
};
