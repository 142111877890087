import { useFeature } from "../../hooks";

const OnboardingVideosPage = () => {
  const liteVersion = useFeature("lite-version");

  return (
    <>
      <script src="https://go.screenpal.com/player/appearance/cTnh6wnfWje"></script>
      <iframe
        // src="https://doranordicaps.uqualio.com/site/17ba8870f4bd4cbeb70484c1bd926588"
        src={
          liteVersion
            ? "https://go.screenpal.com/channels/cTn1qpV0u4/show-carousel"
            : "https://go.screenpal.com/channels/cZXTDDV0ii/show-sidebar"
        }
        title="Online disponering, ruteplanlægning og fakturering"
        height="100%"
        width="100%"
        allow="autoplay; fullscreen"
      ></iframe>
    </>
  );
};

export default OnboardingVideosPage;
