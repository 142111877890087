import { Form } from "./types";
import { useCallback } from "react";
import { useAppDispatch } from "../../../../../redux-store";
import { addLine } from "../../../../../ducks/data/deliveries";
import InvoiceLineRowForm from "./InvoiceLineRowForm";

const AddInvoiceLineRow = (props: {
  deliveryId: string;
  clientId?: string;
  onCancel: () => void;
  onAfterCreate: (newLineIds: string[]) => void;
}) => {
  const { deliveryId, onAfterCreate, clientId } = props;
  const dispatch = useAppDispatch();
  const handleSubmit = useCallback(
    async (values: Form) => {
      const response = await dispatch(
        addLine({
          deliveryId,
          ...values,
          quantity: values.quantity.toString(),
          amount: values.amount.toString(),
        })
      ).unwrap();
      onAfterCreate(response);
    },
    [dispatch, deliveryId, onAfterCreate]
  );
  return (
    <InvoiceLineRowForm
      defaultValues={{
        description: "",
        productId: null,
        unitId: null,
        quantity: "1",
        amount: "",
        discountPercentage: "",
      }}
      clientId={clientId}
      onSubmit={handleSubmit}
      {...props}
    />
  );
};

export default AddInvoiceLineRow;
