import { Chip, Tooltip, Stack } from "@mui/material";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { TrailerTypeAvatar } from "../../../pages/UpcomingPage/trailer-type-avatar";
import { useSelector } from "react-redux";
import { TrailerHover } from "../../modals/trailers/trailer-hover";
import { formatCustomDateString } from "../../../helpers/date-helper";
import RouteDrivers from "./RouteDrivers";
import AddDrivers from "./AddDrivers";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { selectRouteViewModel } from "../../../ducks/data/route-views/selectors";
import {
  addDriver,
  removeDriver,
  updateRouteTrailer,
} from "../../../ducks/routes";
import { useAppDispatch } from "../../../redux-store";
import "./RouteHeader.css";
import TrailerListAutocompleteForRoute from "../../modals/trailers/TrailerListAutocompleteForRoute";
import { selectDrivers } from "../../../ducks/data/drivers/selectors";
import { useFeature } from "../../../hooks";

interface Props {
  routeId: string;
  subheader?: React.ReactNode;
  openCloseIcon?: React.ReactNode;
  onClose?: () => void;
  isWide?: boolean;
}

const RouteHeader = (props: Props) => {
  const dispatch = useAppDispatch();
  const routeViewModel = useSelector(selectRouteViewModel(props.routeId));
  const drivers = useSelector(selectDrivers);
  const routeDrivers =
    useSelector(selectRouteViewModel(props.routeId))?.drivers || null;
  const liteVersion = useFeature("lite-version");

  const startDate = routeViewModel?.startDate
    ? formatCustomDateString(routeViewModel.startDate, "dd.MM")
    : "--/--";
  const endDate = routeViewModel?.endDate
    ? formatCustomDateString(routeViewModel.endDate, "dd.MM")
    : "--/--";

  if (!routeViewModel) {
    return null;
  }

  const onDriverClick = (driverId: string, checked: boolean) => {
    const args = { routeId: props.routeId, driverId };
    dispatch(checked ? addDriver(args) : removeDriver(args))
      .unwrap()
      .then(() => {
        if (checked) {
          const driver = drivers.find((d) => d.id === driverId);
          if (!driver) {
            console.error(`Driver with id ${driverId} not found`);
            return;
          }
          if (!routeViewModel.trailerId && driver.defaultTrailerId) {
            dispatch(
              updateRouteTrailer({
                id: routeViewModel.id,
                trailer_id: driver.defaultTrailerId,
              })
            );
          }
        }
      });
  };

  return (
    <div className="route-header">
      <div className="route-header__avatar">
        <TrailerAvatar trailerId={routeViewModel.trailerId} />
      </div>
      <div className="route-header__name">
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          <div className="route-header__trailer-select">
            <TrailerListAutocompleteForRoute routeId={props.routeId} />
          </div>
          {routeViewModel.note && (
            <Tooltip title={routeViewModel.note} placement="right">
              <InfoOutlinedIcon
                fontSize="small"
                sx={{
                  display: {
                    xs: "none",
                    lg: "block",
                  },
                }}
              />
            </Tooltip>
          )}
        </Stack>
        <div className="route-header__route-name">{props.subheader}</div>
      </div>
      <div className="route-header__status">
        {!liteVersion && (
          <>
            <div className="hidden xl:block">
              <RouteDrivers routeId={props.routeId} />
            </div>
            <AddDrivers
              onDriverClick={onDriverClick}
              routeDrivers={routeDrivers}
              routeId={props.routeId}
            />
          </>
        )}
        <div className="hidden xl:block">
          <Chip
            icon={
              <CalendarTodayOutlinedIcon
                style={{
                  fontSize: 14,
                  marginLeft: 4,
                }}
              />
            }
            label={`${startDate} - ${endDate}`}
            color="primary"
            size="small"
            sx={{
              display: "flex",
              paddingLeft: 0.5,
            }}
          />
        </div>
        {props.openCloseIcon}
      </div>
    </div>
  );
};

export const TrailerAvatar = ({ trailerId }: { trailerId: string | null }) => (
  <TrailerHover trailerId={trailerId}>
    <div>
      <TrailerTypeAvatar trailerId={trailerId} />
    </div>
  </TrailerHover>
);

export default RouteHeader;
