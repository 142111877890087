import { useTranslation } from "react-i18next";
import { useL10n } from "../../../../../l10n";
import { useWatch } from "react-hook-form";
import { Form } from "./types";
import Decimal from "decimal.js-light";

const Total = () => {
  const l10n = useL10n();
  const { t } = useTranslation("invoicing");
  const [quantity, amount, discountPercentage] = useWatch<Form>({
    name: ["quantity", "amount", "discountPercentage"],
  }).map((x) => (x ? l10n.parseDecimal(x) : null));
  if (quantity && amount) {
    const result = quantity
      .mul(amount)
      .div(100)
      .mul(new Decimal(100).sub(discountPercentage || 0));
    return (
      <span aria-label={t("invoiceLine.sum")}>
        {l10n.formatCurrency(result)}
      </span>
    );
  } else {
    return null;
  }
};

export default Total;
