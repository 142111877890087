import { useCallback, useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { StopPiece } from "../../../ducks/app/cargo-and-template-shared";
import Decimal from "decimal.js-light";
import { getLoadmeterValueFromUnitAndQuantity } from "dora-shared";
import CreateEditShipmentData, {
  CreateEditShipmentStop,
} from "./CreateEditShipmentData";
import { DateTime } from "luxon";
import { getAccumulatedCargoPieces } from "./get-accumulated-cargo-pieces";
import CreateShipmentData from "./CreateEditShipmentData";
import { getEstimatedDistanceForCoords } from "../../../api";
import * as actions from "../../../ducks/data/economics/customer-contacts";
import { useAppDispatch } from "../../../redux-store";

const useShipmentFormRules = (
  formMethods: UseFormReturn<CreateEditShipmentData>,
  setDropoffAccumulatedInputsDisabled: (disabled: boolean) => void,
  setCustomerContactId: (id: string | null) => void,
  contacts: any[] | null,
  stopsCoords: { lat: number; lon: number }[],
  setStopsCoords: (coords: { lat: number; lon: number }[]) => void,
  setEstimatedDistance: (distance: number | null) => void,
  qualitiesId: string
) => {
  const dispatch = useAppDispatch();

  const { setValue, getValues, setFocus, watch } = formMethods;

  const combineTags = (stops: CreateEditShipmentStop[]) =>
    stops
      .map((s) => s.tag)
      .filter((t) => t)
      .join("/");

  const reactOnSortingDateChange = useCallback(
    (changes: any) => {
      if (changes.name?.endsWith("].date") && changes.type === "change") {
        const prefix = changes.name.slice(0, changes.name.indexOf(".date"));
        setValue(`${prefix}.HAS_SORTING_DATE_BEEN_CHANGED` as any, true);
      }
    },
    [setValue]
  );

  const changeDropoffSortingDateBasedOnPickupSortingDate = useCallback(() => {
    const maxPickupsDate = DateTime.max(
      ...getValues("pickupList").map((p) => p.date)
    );
    const values = getValues();
    for (let i = 0; i < values.dropoffList.length; i++) {
      const dropoff = values.dropoffList[i];
      if (dropoff.date < maxPickupsDate) {
        setValue(`dropoffList[${i}].date` as any, maxPickupsDate);
      }
    }
  }, [getValues, setValue]);

  const changeSortingDateOnOpeningDateChange = useCallback(
    (changes: any) => {
      if (
        changes.name?.endsWith("].openingDate") &&
        changes.type === "change"
      ) {
        const prefix = changes.name.slice(
          0,
          changes.name.indexOf(".openingDate")
        );
        const hasSortingDateBeenChanged = getValues(
          `${prefix}.HAS_SORTING_DATE_BEEN_CHANGED` as any
        );
        if (!hasSortingDateBeenChanged) {
          const val = getValues(changes.name);
          setValue(`${prefix}.date` as any, val);
          changeDropoffSortingDateBasedOnPickupSortingDate();
        }
      }
    },
    [getValues, setValue, changeDropoffSortingDateBasedOnPickupSortingDate]
  );

  const reactOnClientChange = useCallback(
    (changes: any) => {
      if (changes.name === "clientId" && changes.type === "change") {
        const clientId = changes.values.clientId;
        if (clientId) {
          dispatch(actions.loadCustomerContacts(clientId));
        }
        setCustomerContactId(null);
        setValue("clientContact", "");
        setValue("clientContactPhone", "");
        setValue("clientContactEmail", "");
      }
    },
    [setValue, dispatch, setCustomerContactId]
  );

  const disableDropoffAccumulatedInputsIfOneDropoff = useCallback(() => {
    const values = getValues("dropoffList");
    if (values.length === 1) {
      setDropoffAccumulatedInputsDisabled(true);
    } else {
      setDropoffAccumulatedInputsDisabled(false);
    }
  }, [getValues, setDropoffAccumulatedInputsDisabled]);

  const reactOnClientContactChange = useCallback(
    (changes: any) => {
      if (changes.name === "clientContact" && changes.type === "change") {
        const clientName = changes.values.clientContact;
        if (contacts) {
          const contact = contacts.find((c) => c.name === clientName);
          if (contact) {
            setValue("clientContactPhone", contact.phone || "");
            setValue("clientContactEmail", contact.email || "");
          }
        }
      }
    },
    [contacts, setValue]
  );

  const resolveCustomerContactId = useCallback(
    (changes: any) => {
      if (changes.name === "clientContact" && changes.type === "change") {
        if (contacts) {
          const contact = contacts.find(
            (c) => c.name === changes.values.clientContact
          );
          if (contact) {
            setCustomerContactId(contact.id);
          } else {
            setCustomerContactId(null);
          }
        }
      }
    },
    [setCustomerContactId, contacts]
  );

  const focusNextElementIfPossible = useCallback(
    (changes: any) => {
      if (changes.type !== "change") {
        return;
      }
      if (changes.name === "type") {
        setFocus("description");
      }
      if (changes.name === "clientId") {
        if (changes.values.clientId === null) {
          return;
        }
        setFocus("invoiceRef");
      }
      if (changes.name === "assignedTeamId") {
        const el = document.getElementById(qualitiesId);
        el?.parentElement?.click();
      }
    },
    [setFocus, qualitiesId]
  );

  const reactOnPickupSortingDateChange = useCallback(
    (changes: any) => {
      if (changes.name?.endsWith("].date") && changes.type === "change") {
        changeDropoffSortingDateBasedOnPickupSortingDate();
      }
    },
    [changeDropoffSortingDateBasedOnPickupSortingDate]
  );

  const reactOnPickupOpeningDateChange = useCallback(
    (changes: any) => {
      if (
        changes.name?.endsWith("].openingDate") &&
        changes.type === "change"
      ) {
        const pickupOpeningDates = getValues("pickupList")
          .map((p) => p.openingDate)
          .filter((x) => x) as DateTime[];
        const maxPickupsDate = DateTime.max(...pickupOpeningDates);
        const values = getValues();
        for (let i = 0; i < values.dropoffList.length; i++) {
          const dropoff = values.dropoffList[i];
          if (!dropoff.openingDate || dropoff.openingDate < maxPickupsDate) {
            setValue(`dropoffList[${i}].openingDate` as any, maxPickupsDate);
          }
        }
      }
    },
    [getValues, setValue]
  );

  const calculateLoadmetersFromUnits = useCallback(
    (changes: any) => {
      if (changes.name?.includes("].pieces")) {
        const index = changes.name.substring(
          changes.name.indexOf("[") + 1,
          changes.name.indexOf("]")
        );
        const pickupOrDropoffList = changes.name.substring(
          0,
          changes.name.indexOf("[")
        );
        const pieces = getValues(
          `${pickupOrDropoffList}[${index}].pieces` as any
        );
        if (!pieces) {
          return;
        }
        const piecesWithUnits = pieces.filter(
          (p: StopPiece) => p.unit && !Number.isNaN(p.quantity)
        );
        if (piecesWithUnits.length) {
          const sum = piecesWithUnits.reduce((total: Decimal, b: StopPiece) => {
            const value = getLoadmeterValueFromUnitAndQuantity(
              b.unit as any,
              b.quantity
            );
            if (value) {
              return total.add(new Decimal(value));
            }
            return total;
          }, new Decimal(0));
          setValue(
            `${pickupOrDropoffList}[${index}].loadmeters` as any,
            sum.toString()
          );
        }
      }
    },
    [getValues, setValue]
  );

  const setCmrTagLockedForStop = useCallback(
    (changes: any) => {
      if (changes.name?.endsWith("].tag") && changes.type === "change") {
        // TODO: do that for that exact stop
        const formFragment = changes.name?.slice(
          0,
          changes.name?.indexOf(".tag") + 1
        );
        // console.log("should set locked for ", formFragment + CMR_TAG_LOCKED);
        setValue(`${formFragment}CMR_TAG_LOCKED` as any, true);
      }
    },
    [setValue]
  );

  const reactOnStopsLocationsChange = useCallback(
    async (values: CreateShipmentData) => {
      const stopsCoordsInOrder = [...values.pickupList, ...values.dropoffList]
        .map((s) => s.place?.coord)
        .filter((c) => c);
      if (JSON.stringify(stopsCoords) !== JSON.stringify(stopsCoordsInOrder)) {
        setStopsCoords(stopsCoordsInOrder as { lat: number; lon: number }[]);
        if (stopsCoordsInOrder.length < 2) {
          setEstimatedDistance(null);
          return;
        }
        const res = await getEstimatedDistanceForCoords(
          stopsCoordsInOrder as { lat: number; lon: number }[]
        );
        setEstimatedDistance(res.data.km);
      }
    },
    [stopsCoords, setEstimatedDistance, setStopsCoords]
  );

  const reactOnLoadingRefChanged = useCallback(
    (changes: any) => {
      if (changes.name === "pickupList[0].ref" && changes.type === "change") {
        setValue("LOADING_REF_LOCKED", true);
      }
    },
    [setValue]
  );

  const reactOnContainerNoChanged = useCallback(
    (changes: any) => {
      if (changes.name === "containerNo" && changes.type === "change") {
        const loadingRefLocked = getValues("LOADING_REF_LOCKED");
        if (!loadingRefLocked) {
          setValue("pickupList[0].ref" as any, changes.values.containerNo);
        }
      }
    },
    [getValues, setValue]
  );

  const reactOnPickupNumberChanged = useCallback(
    (changes: any) => {
      if (changes.name === "pickupList") {
        if (getValues("pickupList").length > 1) {
          setValue("driverCanAddDropoffs", false);
        }
      }
    },
    [getValues, setValue]
  );

  const adjustCmrTagsWhenStopsChanged = useCallback(
    (changes: any, values: CreateEditShipmentData) => {
      if (
        changes.name === "pickupList" ||
        changes.name === "dropoffList" ||
        (changes.name?.endsWith("].tag") && changes.type === "change")
      ) {
        const { pickupList, dropoffList } = values;

        if (pickupList.length === 1 && dropoffList.length === 1) {
          if (pickupList[0].tag && !dropoffList[0].CMR_TAG_LOCKED) {
            setValue(`dropoffList[0].tag` as any, pickupList[0].tag);
          }
          if (!values.INVOICE_REF_LOCKED) {
            setValue("invoiceRef", pickupList[0].tag);
          }
        }

        // if multiple pickups copy the first to the non modified ones
        // also dropoff should be automatically calculated if not manually modified
        if (pickupList.length > 1 && dropoffList.length === 1) {
          const firstPickupCmr = pickupList[0].tag;
          if (firstPickupCmr) {
            const restOfPickups = pickupList.slice(1);
            for (let i = 0; i < pickupList.length - 1; i++) {
              const pickup = restOfPickups[i];
              if (!pickup.tag) {
                setValue(`pickupList[${i + 1}].tag` as any, firstPickupCmr);
              }
            }

            const combinedTags = combineTags(pickupList);
            if (!dropoffList[0].CMR_TAG_LOCKED) {
              setValue(`dropoffList[0].tag` as any, combinedTags);
            }
            if (!values.INVOICE_REF_LOCKED) {
              setValue("invoiceRef", combinedTags);
            }
          }
        }

        // if multiple dropoffs copy the first pickup to the non modified ones
        // also pickup should be automatically calculated if not manually modified
        if (pickupList.length === 1 && dropoffList.length > 1) {
          const firstDropoffCmr = dropoffList[0].tag;
          if (firstDropoffCmr) {
            const restOfDropoffs = dropoffList.slice(1);
            for (let i = 0; i < dropoffList.length - 1; i++) {
              const dropoff = restOfDropoffs[i];
              if (!dropoff.tag) {
                setValue(`dropoffList[${i + 1}].tag` as any, firstDropoffCmr);
              }
            }

            const combinedTags = combineTags(dropoffList);
            if (!pickupList[0].CMR_TAG_LOCKED) {
              setValue(`pickupList[0].tag` as any, combinedTags);
            }
            if (!values.INVOICE_REF_LOCKED) {
              setValue("invoiceRef", combinedTags);
            }
          }
        }
      }
    },
    [setValue]
  );

  const reactOnAccumulatedFieldChange = useCallback(
    (
      changes: any,
      name: keyof CreateEditShipmentStop,
      pickupPoints: CreateEditShipmentStop[]
    ) => {
      if (
        changes.name === "pickupList" ||
        (changes.name?.endsWith(`].${name as any}`) &&
          changes.type === "change")
      ) {
        const pickupsFieldsValuesSum = pickupPoints
          .map((p) => p[name])
          .reduce(
            (a, b) => a.add(new Decimal((b as string) || 0)),
            new Decimal(0)
          );
        const dropoffList = getValues("dropoffList");
        if (dropoffList.length) {
          setValue(
            `dropoffList[0].${name as any}` as any,
            pickupsFieldsValuesSum.toString()
          );
        }
      }
    },
    [setValue, getValues]
  );

  const calculateSingleDropoffUnits = useCallback(
    (pickupPoints: CreateEditShipmentStop[]) => {
      const aggregatedPieces = getAccumulatedCargoPieces(pickupPoints);

      const dropoffList = getValues("dropoffList");
      if (dropoffList.length) {
        // if set directly to the proper value it doesn't work, probably some useForms quirk
        setValue("dropoffList[0].pieces" as any, []);
        setTimeout(() => {
          setValue("dropoffList[0].pieces" as any, aggregatedPieces);
        }, 25);
      }
    },
    [setValue, getValues]
  );

  const calculatePiecesWhenPickupStopPossiblyRemoved = useCallback(
    (changes: any, pickupPoints: CreateEditShipmentStop[]) => {
      // we can't precisely identify a "removed" action, those are the only clues we have
      // at most the recalculation would trigger more often, but without unintended side effects
      if (changes.name === "pickupList" && !changes.type) {
        calculateSingleDropoffUnits(pickupPoints);
      }
    },
    [calculateSingleDropoffUnits]
  );

  const reactOnCargoPiecesChangeForOneDropoff = useCallback(
    (changes: any, pickupPoints: CreateEditShipmentStop[]) => {
      if (
        changes.name?.startsWith("pickupList") &&
        changes.name.includes("].pieces")
      ) {
        calculateSingleDropoffUnits(pickupPoints);
      }
    },
    [calculateSingleDropoffUnits]
  );

  const reactOnInvoiceRefChange = useCallback(
    (changes: any) => {
      if (changes.name === "invoiceRef" && changes.type === "change") {
        setValue("INVOICE_REF_LOCKED", true);
      }
    },
    [setValue]
  );

  const run = useCallback(
    (changes: any) => {
      const values = getValues();
      disableDropoffAccumulatedInputsIfOneDropoff();
      reactOnPickupSortingDateChange(changes);
      changeSortingDateOnOpeningDateChange(changes);
      reactOnSortingDateChange(changes);
      reactOnStopsLocationsChange(values);
      reactOnPickupOpeningDateChange(changes);
      focusNextElementIfPossible(changes);
      reactOnClientContactChange(changes);
      reactOnClientChange(changes);
      reactOnLoadingRefChanged(changes);
      reactOnContainerNoChanged(changes);
      reactOnPickupNumberChanged(changes);
      resolveCustomerContactId(changes);
      calculateLoadmetersFromUnits(changes);
      setCmrTagLockedForStop(changes);
      reactOnInvoiceRefChange(changes);
      reactOnLoadingRefChanged(changes);
      adjustCmrTagsWhenStopsChanged(changes, values);
      if (values.dropoffList.length === 1) {
        const pickupPoints = values.pickupList;
        reactOnAccumulatedFieldChange(changes, "loadmeters", pickupPoints);
        reactOnAccumulatedFieldChange(changes, "weight", pickupPoints);
        reactOnAccumulatedFieldChange(changes, "colli", pickupPoints);
        reactOnAccumulatedFieldChange(changes, "cubicMeters", pickupPoints);
        reactOnCargoPiecesChangeForOneDropoff(changes, pickupPoints);
        calculatePiecesWhenPickupStopPossiblyRemoved(changes, pickupPoints);
      }
    },
    [
      getValues,
      calculateLoadmetersFromUnits,
      reactOnAccumulatedFieldChange,
      reactOnPickupSortingDateChange,
      disableDropoffAccumulatedInputsIfOneDropoff,
      reactOnCargoPiecesChangeForOneDropoff,
      resolveCustomerContactId,
      reactOnStopsLocationsChange,
      reactOnClientContactChange,
      reactOnContainerNoChanged,
      reactOnLoadingRefChanged,
      reactOnPickupNumberChanged,
      focusNextElementIfPossible,
      reactOnClientChange,
      // resolveDropoffCmrTagsAndInvoiceRef,
      setCmrTagLockedForStop,
      reactOnInvoiceRefChange,
      adjustCmrTagsWhenStopsChanged,
      calculatePiecesWhenPickupStopPossiblyRemoved,
      changeSortingDateOnOpeningDateChange,
      reactOnSortingDateChange,
      reactOnPickupOpeningDateChange,
    ]
  );

  const [hasNotRunTheFirstTime, setHasNotRunTheFirstTime] = useState(true);

  return useEffect(() => {
    if (hasNotRunTheFirstTime) {
      setHasNotRunTheFirstTime(false);
      return;
    }

    const subscription = watch((_, changes: any) => run(changes));
    return () => subscription.unsubscribe();
  }, [watch, run, hasNotRunTheFirstTime, setHasNotRunTheFirstTime]);
};

export default useShipmentFormRules;
