import { createSlice } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import axios from "../../../axios";
import keyBy from "lodash/keyBy";
import { AppThunkAction } from "../../../redux-store";
import { createErrorReportingAsyncThunk } from "../../helpers";
import { Client, dispatchingClientT, dispatchingClientsT } from "./types";

export type { Client };

const prefix = "data/clients";

interface State {
  loading: boolean;
  entities: { [key: string]: Client };
  ids: string[];
}
const initialState: State = {
  loading: false,
  entities: {},
  ids: [],
};

export const getClients = createErrorReportingAsyncThunk(
  `${prefix}/get-clients`,
  async () => {
    const { data } = await axios.get("/api/clients");
    return await tPromise
      .decode(dispatchingClientsT, data)
      .then((clients) =>
        clients.sort((a, b) => a.client.localeCompare(b.client))
      );
  }
);

export const setShowArrivalDepartureToDriverForCustomer =
  ({
    clientId,
    showArrivalDepartureToDriver,
  }: {
    clientId: string;
    showArrivalDepartureToDriver: boolean | null;
  }): AppThunkAction =>
  async (dispatch) => {
    const body = { showArrivalDepartureToDriver };
    const { data } = await axios.put(`/api/clients/${clientId}`, body);
    return dispatch(clientUpdated(data));
  };

export const setGroupSurchargesForCustomer =
  ({
    clientId,
    groupSurcharges,
  }: {
    clientId: string;
    groupSurcharges: boolean;
  }): AppThunkAction =>
  async (dispatch) => {
    const body = { groupSurcharges };
    const { data } = await axios.put(
      `/api/clients/${clientId}/set-group-surcharges`,
      body
    );
    return dispatch(clientUpdated(data));
  };

export const setGroupProductsForCustomer =
  ({
    clientId,
    groupProducts,
  }: {
    clientId: string;
    groupProducts: boolean;
  }): AppThunkAction =>
  async (dispatch) => {
    const body = { groupProducts };
    const { data } = await axios.put(
      `/api/clients/${clientId}/set-group-products`,
      body
    );
    return dispatch(clientUpdated(data));
  };

export const setShowAddCubicMetersToDriverForCustomer =
  ({
    clientId,
    showAddCubicMetersToDriver,
  }: {
    clientId: string;
    showAddCubicMetersToDriver: boolean | null;
  }): AppThunkAction =>
  async (dispatch) => {
    const body = { showAddCubicMetersToDriver };
    const { data } = await axios.put(
      `/api/clients/${clientId}/toggle-show-cubic-meters-to-driver`,
      body
    );
    return dispatch(clientUpdated(data));
  };

export const setVisibleToDrivers =
  ({
    clientId,
    visibleToDrivers,
  }: {
    clientId: string;
    visibleToDrivers: boolean;
  }): AppThunkAction =>
  async (dispatch) => {
    const body = { visibleToDrivers };
    const { data } = await axios.patch(`/api/clients/${clientId}`, body);
    return dispatch(clientUpdated(data));
  };

export const setVisibleToDriversMultiple =
  ({ visibleToDrivers }: { visibleToDrivers: boolean }): AppThunkAction =>
  async (dispatch) => {
    const { data } = await axios.post(
      `/api/clients/set-visible-to-drivers-multiple`,
      { visibleToDrivers }
    );
    return dispatch(clientsUpdated(data));
  };

export const setSurchargesForClient = createErrorReportingAsyncThunk(
  `${prefix}/set-surcharges-for-client`,
  async (input: { clientId: string; surchargesIds: string[] }) => {
    await axios.post(`/api/clients/${input.clientId}/set-surcharges`, input);
  }
);

export const clientCreated = createErrorReportingAsyncThunk(
  `${prefix}/created`,
  async (client: unknown) => {
    return tPromise.decode(dispatchingClientT, client);
  }
);

export const clientUpdated = createErrorReportingAsyncThunk(
  `${prefix}/updated`,
  async (client: unknown) => {
    return tPromise.decode(dispatchingClientT, client);
  }
);

export const clientsUpdated = createErrorReportingAsyncThunk(
  `${prefix}/clients-updated`,
  async (clients: unknown) => {
    return tPromise.decode(dispatchingClientsT, clients);
  }
);

const slice = createSlice({
  name: prefix,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getClients.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getClients.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = keyBy(action.payload, "id");
        state.ids = action.payload.map((x) => x.id);
      })
      .addCase(clientUpdated.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
      })
      .addCase(clientsUpdated.fulfilled, (state, action) => {
        for (const client of action.payload) {
          state.entities[client.id] = client;
        }
      })
      .addCase(clientCreated.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
        state.ids.push(action.payload.id);
      });
  },
});

export default slice.reducer;
