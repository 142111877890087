import { Form } from "./types";
import "./IncomeHandler.scss";
import { useL10n } from "../../../../../l10n";
import { useCallback } from "react";
import { useAppDispatch } from "../../../../../redux-store";
import { Line } from "../../../../../ducks/data/deliveries/types";
import { updateLine } from "../../../../../ducks/data/deliveries";
import EditSurchargeRow from "./EditSurchargeRow";
import InvoiceLineRowForm from "./InvoiceLineRowForm";

const EditInvoiceLineRow = (props: {
  line: Line;
  clientId?: string;
  deliveryId: string;
  onCancel: () => void;
  onAfterEdit: () => void;
}) => {
  const dispatch = useAppDispatch();
  const { line, deliveryId, onAfterEdit, onCancel, clientId } = props;
  const l10n = useL10n();
  const handleSubmit = useCallback(
    async (form: Form) => {
      // TODO: round up/down here?
      await dispatch(
        updateLine({
          deliveryId,
          id: line.id,
          ...form,
          quantity: form.quantity.toString(),
          amount: form.amount.toString(),
          discountPercentage: form.discountPercentage?.toString() || null,
        })
      );
      onAfterEdit();
    },
    [dispatch, deliveryId, line, onAfterEdit]
  );

  return hasSurcharge(line) ? (
    <EditSurchargeRow line={line} deliveryId={deliveryId} onCancel={onCancel} />
  ) : (
    <InvoiceLineRowForm
      onSubmit={handleSubmit}
      onCancel={onCancel}
      createdDate={line.date}
      clientId={clientId}
      defaultValues={{
        productId: line.productId,
        description: line.description || "",
        unitId: line.unitId,
        quantity: l10n.formatNumber(line.quantity),
        amount: l10n.formatCurrency(line.amount),
        discountPercentage: line.discountPercentage || "",
      }}
    />
  );
};

const hasSurcharge = (line: {
  percentage: string | null;
}): line is { percentage: string } => !!line.percentage;

export default EditInvoiceLineRow;
