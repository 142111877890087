import { useMemo } from "react";
import { Control, Controller } from "react-hook-form";
import CachedIcon from "@mui/icons-material/Cached";
import { faCircleUser } from "@fortawesome/free-regular-svg-icons";
import { HTMLAttributes } from "react";
import { IconButton, MenuItem } from "@mui/material";
import { DoraSelect } from "../../Toolkit/DoraSelectForClients";
import "./ClientSelect.scss";
import { useAppDispatch } from "../../../redux-store";
import { syncCustomers } from "../../../ducks/data/economics/customers";

type ClientSelectProps = {
  fullWidth?: boolean;
  id?: string;
  placeholder?: string;
  label?: string;
  className?: string;
  error?: boolean;
  autofocus?: boolean;
  autoselect?: boolean;
  controlField?: any;
  value?: string;
  onChange?: (value: string) => void;
  clients: { id: string; client: string; clientNumber: string }[];
  style?: any;
};

const ClientSelect = (props: ClientSelectProps) => {
  const { clients, ...rest } = props;
  const options = useMemo(
    () =>
      clients.map((c) => ({
        value: c.id.toString(),
        label: c.client,
        labelSecondary: c.clientNumber,
      })),
    [clients]
  );
  return (
    <DoraSelect
      {...rest}
      fullWidth
      icon={faCircleUser}
      renderOptionsInBatchesNumber={50}
      options={options}
      customGetOptionLabel={(option: any) => `${option.label}`}
      customRenderOption={(pr: HTMLAttributes<HTMLLIElement>, option: any) => (
        <MenuItem {...pr} key={option.value} disabled={option.unselectable}>
          <span className="client-select-option__secondary">
            {option.labelSecondary} •
          </span>
          {option.label}
        </MenuItem>
      )}
    />
  );
};

const SyncCustomersButton = (props: { label: string }) => {
  const dispatch = useAppDispatch();
  const sync = () => {
    dispatch(syncCustomers());
  };

  return (
    <IconButton aria-label={props.label} onClick={sync}>
      <CachedIcon />
    </IconButton>
  );
};

type ClientsSelectWithRefreshProps = ClientSelectProps & {
  disableRefreshButton?: boolean;
  refreshButtonLabel: string;
  style?: any;
};

export const ClientsSelectWithRefresh = (
  props: ClientsSelectWithRefreshProps
) => {
  const { disableRefreshButton, ...rest } = props;
  return !disableRefreshButton ? (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr auto",
      }}
    >
      <ClientSelect {...rest} />
      <SyncCustomersButton label={props.refreshButtonLabel} />
    </div>
  ) : (
    <ClientSelect {...rest} />
  );
};

const UFClientsSelectWithRefresh = (
  props: Omit<ClientsSelectWithRefreshProps, "value" | "onChange"> & {
    name: string;
    control: Control<any, any>;
  }
) => {
  const { control, name, ...rest } = props;
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <ClientsSelectWithRefresh controlField={field} {...rest} />
      )}
    />
  );
};

export default UFClientsSelectWithRefresh;
