import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerHeader, Drawer, MuiTooltip } from "./nav-styles";
import TopBar from "./topbar";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import FormatListBulletedOutlinedIcon from "@mui/icons-material/FormatListBulletedOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import MoneyIcon from "@mui/icons-material/Money";
import Co2OutlinedIcon from "@mui/icons-material/Co2Outlined";
import FeatureToggle from "../FeatureToggle";
import { useTranslation } from "react-i18next";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { useFeature } from "../../hooks";
import "./navbar.scss";
import { SummarizeOutlined } from "@mui/icons-material";
import Joyride, { CallBackProps, Step } from "react-joyride";
import { useEffect } from "react";

const MenuItem = (props: {
  id?: string;
  text: string;
  icon: React.ReactNode;
  link: string;
  open: boolean;
}) => (
  <MuiTooltip
    disableHoverListener={props.open}
    title={props.text}
    placement="right"
  >
    <ListItem button component={Link} to={props.link} id={props.id}>
      {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
      <ListItemText primary={props.text} />
    </ListItem>
  </MuiTooltip>
);

export default function NavBar() {
  const liteVersion = useFeature("lite-version");
  const [open, setOpen] = React.useState(liteVersion ? true : false);
  const toggleDrawerOpen = () => setOpen((x) => !x);
  const { t } = useTranslation(["translation", "dispatching", "navigation"]);
  const liveViewEnabled = useFeature("live-view");
  const cargoReportEnabled = useFeature("cargos-report");
  const [run, setRun] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const steps: Step[] = [
    {
      target: "body",
      content: (
        <div>
          <h2>
            3 trin til dit CO2 regnskab <br />
            og vejafgiftsberegner
          </h2>
          <p className="font-semibold">
            Opsætningen tager mellem 5-15 minutter.
          </p>
          <ul className="justify-items-start list-decimal space-y-2 mt-8">
            <li>Overfør kunder fra dit bogføringssystem</li>
            <li>Registrér dine trækkende enheder</li>
            <li>Opret en rute med ordrer og viapunkter</li>
          </ul>
        </div>
      ),
      placement: "center",
      styles: {
        options: {
          width: 450,
        },
      },
    },
    {
      target: "#add-customers-settings",
      content: "Her overfører du dine kunder.",
      styles: {
        options: {
          width: 350,
        },
      },
    },
    {
      target: "#step-2",
      content: (
        <div className="text-left">
          <p>Opret dine trækkende enheder her. Husk:</p>
          <p>- Teknisk tilladt totalvægt</p>
          <p>- CO2-emissionsklasse</p>
          <p>- Brændstoftype</p>
          <p>- Km/l</p>
        </div>
      ),

      // disableBeacon: true,
      // disableOverlayClose: true,
      // placement: "right",
    },
    {
      target: "#add-co2-route-button",
      content: "Når alt er sat op, kan du klikke her for at lave en rute.",
    },
    {
      target: "#add-customers-settings",
      content: "Men først skal hente kunder fra dit bogføringssystem.",
    },
  ];

  useEffect(() => {
    const tutorialCompleted = localStorage.getItem("tutorialCompleted");
    if (!tutorialCompleted) {
      setRun(true);
    }
  }, []);

  const handleJoyrideCallback = (data: CallBackProps): void => {
    const { status } = data;
    if (status === "finished" || status === "skipped") {
      localStorage.setItem("tutorialCompleted", "true");
      setRun(false);
      navigate("/settings/integrations");
    }
  };

  return (
    <>
      <TopBar
        handleDrawerOpen={toggleDrawerOpen}
        open={liteVersion ? false : open}
      />

      <Drawer
        variant="permanent"
        open={open}
        style={{ justifyContent: "space-between" }}
        className="left-drawer"
      >
        <DrawerHeader>
          <IconButton onClick={toggleDrawerOpen}>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemUpcoming")}
              link="/upcoming"
              icon={<EventOutlinedIcon />}
              open={open}
            />
          )}
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemDispatching")}
              id="dispatch-page-menu"
              link="/dispatching"
              icon={<DashboardOutlinedIcon />}
              open={open}
            />
          )}
          {liteVersion && (
            <MenuItem
              // text={t("navigation:navbar.menuItemDispatching")}
              text="Opret rute"
              link="/add-route"
              icon={<DashboardOutlinedIcon />}
              open={open}
            />
          )}
          {!liteVersion && liveViewEnabled && (
            <MenuItem
              text={t("navigation:navbar.menuItemLiveView")}
              link="/live-view"
              icon={<MapOutlinedIcon />}
              open={open}
            />
          )}
          <MenuItem
            id="step-2"
            text={t("navigation:navbar.menuItemFleet")}
            link="/fleet"
            icon={<FormatListBulletedOutlinedIcon />}
            open={open}
          />
          {!liteVersion && (
            <MenuItem
              text={t("navigation:navbar.menuItemConsolidatedInvoicing")}
              link="/consolidated-invoicing"
              icon={<MoneyIcon />}
              open={open}
            />
          )}
          {!liteVersion && (
            <FeatureToggle feature="analytics">
              <MenuItem
                text={t("navigation:navbar.menuItemAnalytics")}
                link="/analytics"
                icon={<BarChartOutlinedIcon />}
                open={open}
              />
            </FeatureToggle>
          )}
          <MenuItem
            text={t("navigation:navbar.menuItemEmissions")}
            link="/emissions"
            icon={
              <Co2OutlinedIcon />
              // <FontAwesomeIcon
              //     style={{ padding: "4px" }}
              //     icon={faSeedling}
              // />
            }
            open={open}
          />
          {!liteVersion && cargoReportEnabled && (
            <MenuItem
              text={t("navigation:navbar.menuItemCargoReport")}
              link="/cargos-report"
              icon={<SummarizeOutlined />}
              open={open}
            />
          )}
        </List>
      </Drawer>
      {liteVersion && (
        <Joyride
          steps={steps}
          run={run}
          continuous
          showSkipButton={false}
          disableCloseOnEsc
          disableOverlayClose
          hideCloseButton
          callback={handleJoyrideCallback}
          spotlightPadding={0}
          locale={{ next: "Næste", back: "Tilbage", last: "Start her" }}
          styles={{
            options: {
              zIndex: 10000,
              primaryColor: "#4e7bbc",
            },
            spotlight: {
              backgroundColor: `rgba(255, 255, 255, 0.4)`,
              border: "solid #b7cae4 2px",
              padding: 0,
              margin: 0,
            },
          }}
        />
      )}
    </>
  );
}
