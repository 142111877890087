import { Autocomplete, TextField } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useAppDispatch } from "../../../../redux-store";
import { selectUser } from "../../../../ducks/auth/selectors";
import { usePlacePredictions } from "../../common/places-context";
import SearchResultOption from "./SearchResultOption";
import ManageFavoritesAndTerminalsDialog from "../../ManageFavoritesAndTerminalsDialog";
import { loadSavedLocations } from "../../../../ducks/data/saved-locations";
import {
  selectTerminalLocations,
  selectFavoriteLocations,
} from "../../../../ducks/data/saved-locations/selectors";
import SaveFavoriteLocationDialog from "./SaveFavoriteLocationDialog";
import SavedLocation from "../../ManageFavoritesAndTerminalsDialog/SavedLocation";
import { AddressChange } from "../NewPlaceSearch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSearch } from "@fortawesome/free-solid-svg-icons";
import "./PlaceSearchWithSavedLocations.css";
import { AutoSuggestItem } from "dora-shared";
import { reportError } from "../../../../helpers/error-helper";

// import Paper from "@mui/material/Paper";

export type SearchResultSearchOption = {
  type: "SEARCH_RESULT";
  value: {
    id: string;
    locationType: "SEARCH_RESULT";
    data: AutoSuggestItem;
  };
};

export type OtherSearchOption = {
  type: "SAVED_LOCATION" | "TERMINALS_MENU" | "TERMINALS_BACK" | "ADD_TERMINAL";
  value: {
    id: string;
    locationType?: "FAVORITE" | "TERMINAL";
    data: any;
  };
};

export type SearchOption = SearchResultSearchOption | OtherSearchOption;

type SearchOptionType = SearchOption["type"];

const terminalsMenuOption: SearchOption = {
  type: "TERMINALS_MENU",
  value: {
    id: "TERMINALS_MENU",
    data: null,
  },
};

const terminalsBackOption: SearchOption = {
  type: "TERMINALS_BACK",
  value: {
    id: "TERMINALS_BACK",
    data: null,
  },
};

const addTerminalOption: SearchOption = {
  type: "ADD_TERMINAL",
  value: {
    id: "ADD_TERMINAL",
    data: null,
  },
};

const PlaceSearchWithSavedLocations = (props: {
  id?: string;
  onPlaceSelected: (place: null | AddressChange, extraProps?: {
    contactName: string;
    contactPhoneNumber: string;
    openingTime: string;
  }) => void;
  placeError: boolean;
  noLabel?: boolean;
}) => {
  const { t } = useTranslation(["modals", "components"]);
  const me = useSelector(selectUser);
  const { onPlaceSelected, placeError, noLabel, id } = props;

  const [options, setOptions] = useState<SearchOption[]>([terminalsMenuOption]);
  const [locationToBeSaved, setLocationToBeSaved] =
    useState<SearchOption | null>(null);
  const [showAddNewTerminalDialog, setShowAddNewTerminalDialog] =
    useState(false);
  const [highlightedOption, setHighlightedOption] =
    useState<SearchOption | null>(null);
  const [lastSelectedPlace, setLastSelectedPlace] =
    useState<AddressChange | null>(null);
  const [results, setResults] = useState<AutoSuggestItem[]>([]);
  const { suggest } = usePlacePredictions(me?.searchOrigin);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<SearchOption | null>(null);
  const savedTerminals = useSelector(selectTerminalLocations);
  const savedFavorites = useSelector(selectFavoriteLocations);
  const [terminalsSuggestions, setTerminalsSuggestions] = useState<
    SearchOption[]
  >([]);
  const [favoritesSuggestions, setFavoritesSuggestions] = useState<
    SearchOption[]
  >([]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadSavedLocations());
  }, [dispatch]);

  const setDefaultOptions = useCallback(() => {
    setOptions([
      terminalsMenuOption,
      ...savedFavorites.slice(0, 8).map(mapSavedLocationToSearchOption),
    ]);
  }, [savedFavorites]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setDefaultOptions();
    } else {
      const resultsOptions: SearchOption[] = results.map((r) => ({
        type: "SEARCH_RESULT",
        value: {
          id: r.id,
          locationType: "SEARCH_RESULT",
          data: r,
        },
      }));
      setOptions([
        ...terminalsSuggestions.slice(0, 8),
        ...favoritesSuggestions.slice(0, 8),
        ...resultsOptions,
      ]);
    }
  }, [
    results,
    terminalsSuggestions,
    favoritesSuggestions,
    inputValue,
    setDefaultOptions,
  ]);

  useEffect(() => {
    if (inputValue.length === 0) {
      setTerminalsSuggestions([]);
      setFavoritesSuggestions([]);
    } else {
      setTerminalsSuggestions(
        savedTerminals
          .filter((t) => lowerCaseMatch(inputValue, t.customName))
          .map(mapSavedLocationToSearchOption)
      );
      setFavoritesSuggestions(
        savedFavorites
          .filter((t) => lowerCaseMatch(inputValue, t.customName))
          .map(mapSavedLocationToSearchOption)
      );
    }
  }, [savedTerminals, savedFavorites, inputValue]);

  const lowerCaseMatch = (input: string, value: string) =>
    value.toLowerCase().includes(input.toLowerCase());

  const mapSavedLocationToSearchOption = (
    location: SavedLocation
  ): SearchOption => {
    return {
      type: "SAVED_LOCATION",
      value: {
        id: location.id!,
        locationType: location.type,
        data: location,
      },
    };
  };

  const onInputChange = (input: string) => {
    if (input === "TERMINALS_MENU") {
      return;
    }
    if (input.length === 0) {
      setDefaultOptions();
    } else {
      suggest(
        input,
        {
          type: "AT",
          position: me?.searchOrigin!,
        },
        (err, items) => {
          if (err) {
            reportError({ err, input });
            console.error(err);
            return;
          }
          setResults(items || []);
        }
      );
    }
    setInputValue(input);
  };

  const onBookmarkClick = (option: SearchOption) => {
    setLocationToBeSaved(option);
    resetInputValueAndAutocompleteOptions();
  };

  const onSaveLocationDialogClose = ({
    placeSaved,
    customName,
    place,
  }: {
    placeSaved: boolean;
    customName?: string;
    place?: AddressChange;
  }) => {
    if (placeSaved) {
      setValue(locationToBeSaved!);
      dispatch(loadSavedLocations());
      onPlaceSelected({
        ...lastSelectedPlace!,
        ...place,
        ...(customName && { placeName: customName }),
      });
    }
    setLocationToBeSaved(null);
  };

  const onKeydownPress = (event: any) => {
    if (
      "key" in event &&
      !["Enter", "ArrowLeft", "ArrowRight"].includes(event.key)
    ) {
      return;
    }
    onOptionPreSelect(highlightedOption?.type as SearchOptionType, event.key);
  };

  const onOptionPreSelect = (type: SearchOptionType, key?: string) => {
    if (["TERMINALS_MENU", "TERMINALS_BACK", "ADD_TERMINAL"].includes(type)) {
      switch (type) {
        case "TERMINALS_MENU":
          if (!key || key === "ArrowRight" || key === "Enter") {
            toggleTerminalsMenu({ shouldShow: true });
          }
          break;
        case "TERMINALS_BACK":
          if (!key || key === "ArrowLeft" || key === "Enter") {
            toggleTerminalsMenu({ shouldShow: false });
          }
          break;
        case "ADD_TERMINAL":
          setShowAddNewTerminalDialog(true);
          resetInputValueAndAutocompleteOptions();
          break;
        default:
          return;
      }
    }
  };

  const resetInputValueAndAutocompleteOptions = () => {
    setTimeout(() => {
      setInputValue("");
      setValue(null);
      setDefaultOptions();
    }, 50);
  };

  const getOptionLabel = (option: SearchOption) => {
    switch (option.type) {
      case "SEARCH_RESULT":
        const data = option.value.data;
        if (typeof data === "string") {
          return data;
        }
        if ("description" in data) {
          return data.description;
        }
        if ("title" in data && "label" in data.title) {
          return data.title.label;
        }
        if ("label" in data) {
          return data.label;
        }
        return "";
      case "SAVED_LOCATION":
        return option.value.data.customName;
      default:
        return "";
    }
  };

  const onChange = async (_event: any, newValue: SearchOption | null) => {
    setTimeout(async () => {
      if (locationToBeSaved) {
        return;
      }
      if (newValue) {
        let val: AddressChange | null = null;
        let extraProps: {
          contactName: string;
          contactPhoneNumber: string;
          openingTime: string;
        } | undefined = undefined;
        if (newValue.type === "SEARCH_RESULT") {
          const data = newValue.value.data;
          const lookupResult = await data.lookup();
          const lat = lookupResult.coords.lat;
          const lon = lookupResult.coords.lon;
          if (lat && lon) {
            val = {
              address: lookupResult.address,
              city: lookupResult.city,
              country: lookupResult.countryCode,
              postcode: lookupResult.postcode,
              place: data.label,
              placeName: lookupResult.placeName,
              coord: { lon, lat },
              googlePlaceId: lookupResult.googlePlaceId,
            };
          } else {
            return;
          }
        }
        if (newValue.type === "SAVED_LOCATION") {
          const data = newValue.value.data as SavedLocation;
          val = {
            place: data.place.placeName || "",
            address: data.place.address || "",
            googlePlaceId: data.place.googlePlaceId,
            country: data.place.country || "",
            city: data.place.city || "",
            postcode: data.place.postcode || "",
            coord: data.place.coord,
            placeName: data.place.placeName || "",
          };
          extraProps = {
            contactName: data.contactName,
            contactPhoneNumber: data.contactPhoneNumber,
            openingTime: data.openingTime,
          }
        }
        setLastSelectedPlace(val);
        onPlaceSelected(val, extraProps);
      }
    }, 50);
  };

  const toggleTerminalsMenu = ({ shouldShow }: { shouldShow: boolean }) => {
    if (shouldShow) {
      setOptions([
        terminalsBackOption,
        ...savedTerminals.map(mapSavedLocationToSearchOption),
        addTerminalOption,
      ]);
    } else {
      setDefaultOptions();
    }
  };

  return (
    <>
      {showAddNewTerminalDialog && (
        <ManageFavoritesAndTerminalsDialog
          onClose={() => setShowAddNewTerminalDialog(false)}
          addTerminalOnly
        />
      )}
      {locationToBeSaved && (
        <SaveFavoriteLocationDialog
          location={locationToBeSaved as SearchOption}
          onClose={onSaveLocationDialogClose}
        />
      )}
      <Autocomplete
        {...(id && { id })}
        sx={{
          margin: 0,
          "& fieldset": { borderColor: "var(--gray-300)" },
          "& .MuiOutlinedInput-root:not(.Mui-focused):hover fieldset": {
            borderColor: "var(--gray-400) !important",
          },
          "& .MuiFormControl-root.MuiFormControl-marginDense.MuiFormControl-fullWidth.MuiTextField-root":
            {
              margin: "0 !important",
            },
        }}
        className={placeError ? "dora-place-search__search-error" : ""}
        noOptionsText={t("components:optionLabels.noOptions")}
        getOptionLabel={getOptionLabel}
        filterOptions={(x) => x}
        options={options}
        autoSelect
        selectOnFocus
        disablePortal
        clearOnBlur={false}
        clearOnEscape
        fullWidth
        onKeyDown={onKeydownPress}
        onHighlightChange={(e, o) => setHighlightedOption(o)}
        autoHighlight
        value={value}
        onChange={onChange}
        // ListboxComponent={(props) => (
        //   <Paper
        //       {...props}
        //       style={{ maxHeight: 250, overflowY: "auto" }}
        //   />
        // )}
        inputValue={inputValue}
        onInputChange={(_event, newInputValue) => onInputChange(newInputValue)}
        renderOption={(p, o) => (
          <li
            {...p}
            key={o.value.id}
            onMouseDown={() => onOptionPreSelect(o.type)}
          >
            <SearchResultOption option={o} onBookmarkClick={onBookmarkClick} />
          </li>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            label={
              noLabel
                ? null
                : t("modals:addNewCargo.stepOne.inputFields.searchPlace")
            }
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <div className="dora-place-search__search-icon">
                  <FontAwesomeIcon icon={faSearch} />
                </div>
              ),
              endAdornment: (
                <div className="dora-place-search__caret">
                  <FontAwesomeIcon icon={faAngleDown} />
                </div>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default PlaceSearchWithSavedLocations;
