import { RootState } from "../../../redux-store";
import { createSelector } from "@reduxjs/toolkit";
import { Route } from "./types";
import { Trailer } from "../../data/trailers/types";
import { selectTelemetryPosition } from "../../data/tracker-devices/selectors";

// TODO: Move
const selectEntities = (state: RootState) => state.data.trailers.entities;

// TODO: Move
const selectIds = (state: RootState) => state.data.trailers.ids;

const selectRoutes = (state: RootState) => state.app.fleetManagement.routes;

const projectTrailer = (trailer: Trailer, routes?: Route[]) => ({
  ...trailer,
  active_route: (routes && routes.length && routes[0].name) || "",
  active: (routes && routes.length && routes[0].active) || false,
  drivers: (routes && routes.length && routes[0].drivers) || [],
  latest_current_location: null,
  user: (routes || []).map(({ owner }) => ({
    id: owner.id,
    firstName: owner.firstName,
    lastName: owner.lastName,
    full_name: owner.fullName,
  })),
  destination: (routes && routes.length && routes[0].destination) || "",
  destination_date:
    (routes && routes.length && routes[0].destinationDate) || "",
});

export const selectTrailers = createSelector(
  selectIds,
  selectEntities,
  selectRoutes,
  (ids, entities, routes) =>
    ids.map((id) => projectTrailer(entities[id], routes[id]))
);

export const selectStatus = (state: RootState) =>
  state.app.fleetManagement.status;

// TODO: Move
export const selectTrailer = (id?: string | null) => (state: RootState) =>
  (id && state.data.trailers.entities[id]) || null;

export const selectTrackerDevices = (state: RootState) =>
  state.data.trackerDevices.entities;

export const selectFlespiToken = (state: RootState) =>
  state.data.trackerDevices.token;

// export const selectDevicesData = (state: RootState) =>
//   state.data.trackerDevices.devicesData;

export const selectTrackerForTrailer =
  (trailerId: string | null | undefined) => (state: RootState) => {
    if (!trailerId) {
      return null;
    }
    const data = state.data;
    const trailer = (trailerId && data.trailers.entities[trailerId]) || null;
    const deviceId = trailer && trailer.trackerDeviceId;
    return deviceId ? data.trackerDevices.entities[deviceId] : null;
  };

// export const selectTrailerPosition =
//   (trailerId: string) => (state: RootState) => {
//     const device = selectTrackerForTrailer(trailerId)(state);
//     const flespiId = device && device.flespiId?.toString();
//     const deviceData =
//       flespiId && state.data.trackerDevices.devicesData[flespiId];
//     return deviceData || null;
//   };

export const selectTrailerTelemetry =
  (trailerId: string) => (state: RootState) => {
    const device = selectTrackerForTrailer(trailerId)(state);
    return device && selectTelemetryPosition(device.id)(state);
  };
