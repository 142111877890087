import { yupResolver } from "@hookform/resolvers/yup";
import "./IncomeHandler.scss";
import { useL10n } from "../../../../../l10n";
import { useCallback, useMemo } from "react";
import { useAppDispatch } from "../../../../../redux-store";
import { updateSurchargeLine } from "../../../../../ducks/data/deliveries";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useElementId } from "../../../../../hooks";
import DoraButton from "../../../../Toolkit/DoraButton";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UFDoraTextInput } from "../../../../Toolkit/DoraTextInput";
import ValidationError from "./ValidationError";

const EditSurchargeRow = (props: {
  line: { id: string; percentage: string };
  deliveryId: string;
  onCancel: () => void;
}) => {
  const { line, deliveryId, onCancel } = props;
  const l10n = useL10n();
  const schema = useMemo(
    () =>
      yup.object({
        percentage: yup
          .number()
          .required()
          .positive()
          .transform((_, strValue) => l10n.parseNumber(strValue)),
      }),
    [l10n]
  );
  const defaultValues = {
    percentage: l10n.formatNumber(line.percentage),
  };
  const fieldId = useElementId();
  const formMethods = useForm<{ percentage: string }>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { register, handleSubmit } = formMethods;
  const errors = formMethods.formState.errors;
  const inputValidationProps = useCallback(
    (id: string, field: "percentage") => {
      const fieldError = formMethods.formState.errors[field];
      return fieldError
        ? { "aria-invalid": true, "aria-describedby": id, error: true }
        : {};
    },
    [formMethods.formState]
  );
  const dispatch = useAppDispatch();
  const submitRow = useMemo(() => {
    return handleSubmit(
      (values) => {
        dispatch(
          updateSurchargeLine({
            deliveryId,
            id: line.id,
            percentage: values.percentage.toString(),
          })
        ).then(() => {
          onCancel();
        });
      },
      (fieldErrors) => {}
    );
  }, [dispatch, handleSubmit, deliveryId, onCancel, line.id]);
  const { t } = useTranslation(["components"]);
  return (
    <FormProvider {...formMethods}>
      <form role="row form" className="income-grid__row" onSubmit={submitRow}>
        <div className="add-invoice-line-row__cell" role="gridcell">
          {/* selected */}
        </div>
        <div
          className="add-invoice-line-row__cell"
          role="gridcell"
        ></div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div
          className="add-invoice-line-row__cell"
          role="gridcell"
        ></div>
        <div className="add-invoice-line-row__cell" role="gridcell">
          <UFDoraTextInput
            register={register("percentage")}
            aria-label="Percentage"
            {...inputValidationProps(fieldId, "percentage")}
          />
        </div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div className="add-invoice-line-row__cell" role="gridcell"></div>
        <div className="add-invoice-line-row__last-cell" role="gridcell">
          <DoraButton
            variant="primary"
            className={"add-invoice-line-last-cell__button"}
            title={t("components:buttonLabels.cancel")}
            aria-label={t("components:buttonLabels.cancel")}
            onClick={onCancel}
          >
            <FontAwesomeIcon icon={faTimes} />
          </DoraButton>
          <DoraButton
            variant="primary"
            className={"add-invoice-line-last-cell__button"}
            type="submit"
            title={t("components:buttonLabels.save")}
            aria-label={t("components:buttonLabels.save")}
          >
            <FontAwesomeIcon icon={faCheck} />
          </DoraButton>
        </div>
      </form>

      {errors["percentage"] ? (
        <div className="income-handler-row__errors">
          <ValidationError id={fieldId} field="percentage" />
        </div>
      ) : null}
    </FormProvider>
  );
};

export default EditSurchargeRow;
