import { useSelector } from "react-redux";
import { loadFinance } from "./configuration";
import * as selectors from "./selectors";
import { useAppDispatch } from "../../../redux-store";
import { useEffect } from "react";

export const useFinanceConfiguration = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadFinance());
  }, [dispatch]);
  return useSelector(selectors.selectFinanceConfiguration);
};
