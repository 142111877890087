import axios, { getData, isAxiosError } from "../../../axios";
import { createSlice, createAction, PayloadAction } from "@reduxjs/toolkit";
import * as tPromise from "io-ts-promise";
import * as types from "./types";
import * as selectors from "./selectors";
import * as notificationActions from "../../notifications";
import { createErrorReportingAsyncThunk, unwrap } from "../../helpers";
import { prettyPrintIfDecodeError } from "../../../helpers/io-ts-helpers";
import {
  CreateUpdateExpense,
  createUpdateExpenseT,
  CreateUpdateInvoiceLine,
  createUpdateInvoiceLineT,
  sendInvoiceRequestT,
  updateSurchargeLineT,
  updateInvoiceLineOrderNumberT,
  SendInvoiceType,
} from "./types";

const prefix = "data/deliveries";

export interface UpdateSurchargeLine {
  id: string;
  percentage: string;
}

export type Id = {
  id: string;
};

export interface DeliveryId {
  deliveryId: string;
}

export const sendExpenses = createErrorReportingAsyncThunk(
  `${prefix}/load-expenses`,
  async (input: { deliveryId: string; expenseIds: string[] }, thunkAPI) => {
    const { deliveryId, expenseIds } = input;
    const delivery = await axios
      .post(`/api/deliveries/${deliveryId}/expense-batches`, {
        expenseIds,
      })
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const deleteExpense = createErrorReportingAsyncThunk(
  `${prefix}/delete-expense`,
  async (input: { deliveryId: string; expenseId: string }, thunkAPI) => {
    const { deliveryId, expenseId } = input;
    const delivery = await axios
      .delete(`/api/deliveries/${deliveryId}/expenses/${expenseId}`)
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const deliveryUpdated = createAction<types.Delivery>(
  `${prefix}/updated`
);

export const addExpense = createErrorReportingAsyncThunk(
  `${prefix}/addExpense`,
  async (input: CreateUpdateExpense & DeliveryId, thunkAPI) => {
    const { deliveryId, accountId, amount, currency, date } = input;
    const data = {
      accountId,
      amount,
      currency,
      date,
    };
    const delivery = await axios
      .post(
        `/api/deliveries/${deliveryId}/expenses`,
        createUpdateExpenseT.encode(data)
      )
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const updateExpense = createErrorReportingAsyncThunk(
  `${prefix}/updateExpense`,
  async (
    input: CreateUpdateExpense & { deliveryId: string; expenseId: string },
    thunkAPI
  ) => {
    const { expenseId, deliveryId, accountId, amount, currency, date } = input;
    const data = {
      accountId,
      amount,
      currency,
      date,
    };
    const delivery = await axios
      .put(
        `/api/deliveries/${deliveryId}/expenses/${expenseId}`,
        createUpdateExpenseT.encode(data)
      )
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const loadDelivery = createErrorReportingAsyncThunk(
  `${prefix}/getDelivery`,
  async (deliveryId: string, thunkAPI) => {
    const delivery = await axios
      .get(`/api/deliveries/${deliveryId}`)
      .then((x) => x.data)
      .then(tPromise.decode(types.deliveryT))
      .catch(prettyPrintIfDecodeError);
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const addLine = createErrorReportingAsyncThunk(
  `${prefix}/addLine`,
  async (line: CreateUpdateInvoiceLine & DeliveryId, thunkAPI) => {
    const { deliveryId, ...data } = line;
    const { delivery, newLinesIds } = await axios
      .post(
        `/api/deliveries/${deliveryId}/invoice-lines`,
        createUpdateInvoiceLineT.encode(data)
      )
      .then(getData)
      .then(tPromise.decode(types.deliveryUpdateResponseT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
    return newLinesIds;
  }
);

export const deleteLine = createErrorReportingAsyncThunk(
  `${prefix}/delete`,
  async (input: { deliveryId: string; lineId: string }, thunkAPI) => {
    const { deliveryId, lineId } = input;
    const delivery = await axios
      .delete(`/api/deliveries/${deliveryId}/invoice-lines/${lineId}`)
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
    thunkAPI.dispatch(deliveryUpdated(delivery));
  }
);

export const updateLine = createErrorReportingAsyncThunk(
  `${prefix}/updateLine`,
  async (line: CreateUpdateInvoiceLine & Id & DeliveryId, thunkAPI) => {
    try {
      const { deliveryId, id, ...data } = line;
      const { delivery, newLinesIds } = await axios
        .put(`/api/deliveries/${deliveryId}/invoice-lines/${id}`, data)
        .then(getData)
        .then(tPromise.decode(types.deliveryUpdateResponseT));
      thunkAPI.dispatch(deliveryUpdated(delivery));
      thunkAPI.dispatch(
        notificationActions.notifyL({
          namespace: "notifications",
          key: "invoiceLineUpdated",
        })
      );
      return newLinesIds;
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 409) {
        thunkAPI.dispatch(
          notificationActions.notifyL({
            namespace: "notifications",
            key: "updatingFailed",
            type: "error",
          })
        );
      }
      throw e;
    }
  }
);

export const updateLineOrderNumber = createErrorReportingAsyncThunk(
  `${prefix}/updateLineOrderNumber`,
  async (input: {
    deliveryId: string;
    lineId: string;
    orderNumber: number;
  }) => {
    const { deliveryId, lineId, orderNumber } = input;
    return axios
      .put(
        `/api/deliveries/${deliveryId}/invoice-lines/${lineId}/set-order-number`,
        updateInvoiceLineOrderNumberT.encode({ orderNumber })
      )
      .then(getData)
      .then(tPromise.decode(types.deliveryT));
  }
);

const doUpdateSurchargeLine = createErrorReportingAsyncThunk(
  "data/deliveries/updateSurchargeLine",
  async (line: UpdateSurchargeLine & DeliveryId, thunkAPI) => {
    try {
      const { deliveryId, id, ...data } = line;
      const delivery = await axios
        .put(
          `/api/deliveries/${deliveryId}/invoice-lines/${id}/set-percentage`,
          updateSurchargeLineT.encode(data)
        )
        .then(getData)
        .then(tPromise.decode(types.deliveryT));
      thunkAPI.dispatch(deliveryUpdated(delivery));
      thunkAPI.dispatch(
        notificationActions.notifyL({
          namespace: "notifications",
          key: "invoiceLineUpdated",
        })
      );
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 409) {
        thunkAPI.dispatch(
          notificationActions.notifyL({
            namespace: "notifications",
            key: "updatingFailed",
            type: "error",
          })
        );
      }
      throw e;
    }
  }
);

export const updateSurchargeLine = unwrap(doUpdateSurchargeLine);

export const sendInvoice = createErrorReportingAsyncThunk(
  "data/deliveries/sendInvoice",
  async (
    input: {
      deliveryId: string;
      selectedLineIds: string[];
      clientId?: string;
      customerContactId?: string | null;
      departmentId?: string | null;
      attachmentFileIds?: string[];
      folderName?: string;
      type: SendInvoiceType;
    },
    thunkAPI
  ) => {
    const {
      deliveryId,
      clientId,
      customerContactId,
      departmentId,
      selectedLineIds,
      attachmentFileIds,
      type,
      folderName,
    } = input;
    const revision = selectors.selectRevision(deliveryId)(thunkAPI.getState());
    try {
      const delivery = await axios
        .post(
          `/api/deliveries/${deliveryId}/invoices`,
          sendInvoiceRequestT.encode({
            revision,
            clientId,
            customerContactId,
            departmentId,
            lineIds: selectedLineIds,
            attachmentFileIds,
            folderName,
            type,
          })
        )
        .then((x) => x.data)
        .then(tPromise.decode(types.deliveryT));
      thunkAPI.dispatch(deliveryUpdated(delivery));
    } catch (e: any) {
      if (isAxiosError(e) && e.response?.status === 409) {
        thunkAPI.dispatch(
          notificationActions.notifyL({
            namespace: "notifications",
            key: "invoiceFailedDueToEditedLines",
            type: "error",
          })
        );
        const delivery = await tPromise.decode(
          types.deliveryT,
          e.response?.data
        );
        thunkAPI.dispatch(deliveryUpdated(delivery));
      } else {
        throw e;
      }
    }
  }
);

interface DeliveriesState {
  entities: {
    [key: string]: types.Delivery;
  };
}

const initialState: DeliveriesState = {
  entities: {},
};

const slice = createSlice({
  name: "data/deliveries",
  initialState,
  reducers: {
    unloadDelivery: (state, action: PayloadAction<string>) => {
      delete state.entities[action.payload];
    },
  },
  extraReducers: (builder) => {
    builder.addCase(deliveryUpdated, (state, action) => {
      state.entities[action.payload.id] = action.payload;
    });
    builder.addCase(updateLineOrderNumber.fulfilled, (state, action) => {
      state.entities[action.payload.id] = action.payload;
    });
  },
});

export default slice.reducer;
export const { unloadDelivery } = slice.actions;
