import Decimal from "decimal.js-light";
import { cargoTypes, CargoType, TrailerType } from "dora-contracts";
export { cargoTypes };
export type { CargoType, TrailerType };

export type TrailerQuality =
  | "SIDE_OPEN"
  | "WALKING_FLOOR"
  | "LIFT"
  | "HOOK"
  | "PALLET_LIFTER"
  | "FORKLIFT"
  | "RAISED_ROOF"
  | "CRANE"
  | "HOOK_HOIST"
  | "BACK_TIP"
  | "STEP_DECK"
  | "RAMPS"
  | "SIDE_TIP"
  | "PARTITION"
  | "SLIDING_ROOF"
  | "FLATBED";

export type CargoQualities =
  | "ADR"
  | "A_SIGN"
  | "WIDE_CARGO"
  | "MOVING_GOODS"
  | "HIGH_GOODS"
  | "LONG_GOODS"
  | "FURNITURE"
  | "SPIRITS"
  | "STRAPS"
  | "SPECIAL_TRANSPORT"
  | "CUSTOMS"
  | "TROPHIES"
  | "WOOD_ACTIVATORS"
  | "T1_GOODS";

export const trailerQualities: TrailerQuality[] = [
  "SIDE_OPEN",
  "WALKING_FLOOR",
  "LIFT",
  "HOOK",
  "PALLET_LIFTER",
  "FORKLIFT",
  "RAISED_ROOF",
  "CRANE",
  "HOOK_HOIST",
  "BACK_TIP",
  "STEP_DECK",
  "RAMPS",
  "SIDE_TIP",
  "PARTITION",
  "SLIDING_ROOF",
  "FLATBED",
];

export const cargoQualities: CargoQualities[] = [
  "ADR",
  "A_SIGN",
  "WIDE_CARGO",
  "MOVING_GOODS",
  "HIGH_GOODS",
  "LONG_GOODS",
  "FURNITURE",
  "SPIRITS",
  "STRAPS",
  "SPECIAL_TRANSPORT",
  "CUSTOMS",
  "TROPHIES",
  "WOOD_ACTIVATORS",
  "T1_GOODS",
];

type TrailerTypeSpec = {
  type: TrailerType;
  powered: boolean;
  lm?: number;
  height?: number;
  width?: number;
  length?: number;
  weight?: number;
  cbm?: number;
  departmentId?: string | null;
  alias?: string | null;
};

export const calcCbm = (t?: TrailerTypeSpec) => {
  if (!t) {
    return undefined;
  }
  if (t.cbm) {
    return t.cbm;
  }
  const height = t.height && new Decimal(t.height / 100);
  const width = t.width && new Decimal(t.width / 100);
  const length = t.length && new Decimal(t.length / 100);
  return (
    height &&
    width &&
    length &&
    height.mul(width).mul(length).toDecimalPlaces(2).toNumber()
  );
};

export const trailerTypes: TrailerTypeSpec[] = [
  {
    type: "SEMI_TRUCK",
    powered: true,
    lm: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  {
    type: "FRIGO",
    powered: false,
    lm: 13.31,
    height: 260,
    width: 248,
    length: 1331,
    weight: 9000,
  },
  {
    type: "CURTAIN_SIDE",
    powered: false,
    lm: 13.6,
    height: 270,
    width: 248,
    length: 1362,
    weight: 7200,
  },
  {
    type: "BOX",
    powered: false,
    lm: 13.6,
    height: 270,
    width: 246,
    length: 1362,
    weight: 8600,
  },
  {
    type: "FLATBED",
    powered: false,
    lm: 13.6,
    height: 1000,
    width: 248,
    length: 1362,
    weight: 7200,
  },
  {
    type: "AUTO_TRAILER",
    powered: false,
    lm: 0,
    height: 1000,
    width: 250,
    length: 0,
    weight: 0,
  },
  {
    type: "BULK",
    powered: false,
  },
  {
    type: "ANIMAL_TRANSPORT",
    powered: false,
  },
  {
    type: "CONCRETE_CANNON",
    powered: true,
    lm: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  {
    type: "CONTAINER_CHASSIS",
    powered: false,
    lm: 13.6,
    height: 1000,
    width: 255,
    length: 1360,
    weight: 5100,
  },
  {
    type: "CONTAINER",
    powered: false,
    lm: 12.03,
    height: 228,
    width: 234,
    length: 1203,
    weight: 3800,
  },
  {
    type: "RIGID_HGV", // FORVOGN
    powered: true,
    lm: 7.5,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  {
    type: "JUMBO",
    powered: false,
    lm: 13.6,
    height: 310,
    width: 245,
    length: 1360,
    weight: 7200,
  },
  {
    type: "MEGA",
    powered: false,
    lm: 13.6,
    height: 300,
    width: 245,
    length: 1360,
    weight: 7200,
  },
  {
    type: "VAN", //Varevogn
    powered: true,
    lm: 3.73,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  {
    type: "TANKER",
    powered: false,
  },
  {
    type: "THERMO",
    powered: false,
  },
  {
    type: "DUMPERLOAD",
    powered: false,
  },
  {
    type: "RIGID_HGV_TRAILER", //Forvogn+hænger
    powered: true,
    lm: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
  {
    type: "BLOCK_WAGON", // Blokvogn
    powered: false,
  },
  {
    type: "OTHER",
    powered: false,
  },
  {
    type: "OTHER_POWERED",
    powered: true,
    lm: 0,
    height: 0,
    width: 0,
    length: 0,
    weight: 0,
  },
];

export const units = {
  entities: {
    "EUR-PL-QUARTER": {
      value: 20,
    },
    "EUR-PL-HALF": {
      value: 40,
    },
    "EUR-PL": {
      value: 80,
    },
    "UK-PL": {
      value: 100,
    },
    FTL: {
      value: 0,
    },
    UNIT: {
      value: 0,
    },
    "BB-LG": {
      value: 0,
    },
    "BB-XL": {
      value: 0,
    },
    OTHER: {
      value: 0,
    },
  },
};

export type UnitId = keyof (typeof units)["entities"];

const unitIds: UnitId[] = [
  "EUR-PL-QUARTER",
  "EUR-PL-HALF",
  "EUR-PL",
  "UK-PL",
  "FTL",
  "UNIT",
  "BB-LG",
  "BB-XL",
  "OTHER",
];

export const getUnits = () =>
  unitIds.map((id) => ({ id, ...units.entities[id] }));

export const getUnit = (key: UnitId) => {
  return units.entities[key];
};
