import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useSelector } from "react-redux";
import { selectTrailers } from "../../../ducks/data/trailers/selectors";
import { TrailerHover } from "./trailer-hover";
import { useTranslation } from "react-i18next";
import "./trailer-list-autocomplete.scss";
import { preventClickPropagation } from "../../../helpers/prevent-click-propagation";
import { useFeature } from "../../../hooks";

interface Props {
  handleTrailerChange: (event: { id: string } | null) => void;
  routeTrailerId: string | null;
}

export const TrailerListAutocomplete = (props: Props) => {
  const { handleTrailerChange, routeTrailerId } = props;
  const { t } = useTranslation("dispatching");
  const liteVersion = useFeature("lite-version");

  const trailers = useSelector(selectTrailers);
  const options = trailers.map((trailer) => ({
    id: trailer.id,
    label: trailer.alias || trailer.number,
    alias: trailer.alias,
    number: trailer.number,
  }));
  const value = options.find((x) => x.id === routeTrailerId) || null;

  return (
    <Autocomplete
      value={value}
      onChange={(event, newValue) => {
        preventClickPropagation(event);
        handleTrailerChange(newValue);
      }}
      renderOption={(props, option) => (
        <li {...props}>
          <div>
            <div>{option.label}</div>
            {option.alias && (
              <div className="trailer-list-autocomplete__option-number-subtext">
                {option.number}
              </div>
            )}
          </div>
        </li>
      )}
      options={options}
      filterOptions={(options, params) => {
        return options.filter((option) => {
          const lowerCaseInput = params.inputValue.toLowerCase();
          return (
            option.label.toLowerCase().includes(lowerCaseInput) ||
            option.number.toLowerCase().includes(lowerCaseInput)
          );
        });
      }}
      forcePopupIcon={false}
      selectOnFocus
      handleHomeEndKeys
      sx={{ width: 180 }}
      renderInput={(params) => (
        <TrailerHover trailerId={routeTrailerId}>
          <TextField
            {...params}
            onClick={preventClickPropagation}
            placeholder={t("routeCard.selectTrailer")}
            variant={liteVersion ? "outlined" : "standard"}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
          />
        </TrailerHover>
      )}
    />
  );
};

export default TrailerListAutocomplete;
