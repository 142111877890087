import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import { useMemo } from "react";
import { selectInvoiceLines } from "../../../../ducks/data/deliveries/selectors";
import { DateTime } from "luxon";
import { useL10n } from "../../../../l10n";
import { useAppDispatch, useSelector } from "../../../../redux-store";
import { Form, Field, Formik } from "formik";
import { MuiTextField } from "../../../formik";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { updateSurchargeLine } from "../../../../ducks/data/deliveries";
import { notify } from "../../../../ducks/notifications";
import { useActions } from "../../../../hooks/useActions";

const useInvoiceLine = ({
  deliveryId,
  invoiceLineId,
}: {
  deliveryId: string;
  invoiceLineId: string;
}) => {
  const invoiceLines = useSelector(selectInvoiceLines(deliveryId));
  const line = useMemo(() => {
    const line = invoiceLines.find((x) => x.id === invoiceLineId);
    if (!line) {
      return null;
    }
    return { ...line, date: DateTime.fromISO(line.date) };
  }, [invoiceLines, invoiceLineId]);
  return line;
};

const EditSurchargeLineModal = (props: {
  onClose: () => void;
  id: { deliveryId: string; invoiceLineId: string };
}) => {
  const l10n = useL10n();
  const { id, onClose } = props;
  const dispatch = useAppDispatch();
  const actions = useActions({ updateSurchargeLine });
  const line = useInvoiceLine(id);
  const { t } = useTranslation(["translation", "invoicing", "components"]);
  const validationSchema = useMemo(
    () =>
      yup.object({
        percentage: yup
          .number()
          .label("Percentage")
          .transform((_, strValue) => l10n.parseNumber(strValue)),
      }),
    [l10n]
  );

  const onSubmit = async (formData: any) => {
    try {
      if (line) {
        await actions.updateSurchargeLine({
          id: id.invoiceLineId,
          deliveryId: id.deliveryId,
          percentage: formData.percentage.toString(),
        });
      } else {
        // await dispatch(
        //   actions.addLine({
        //     ...formData,
        //     deliveryId: id.deliveryId,
        //     quantity: l10n.parseNumber(formData.quantity).toString(),
        //     amount: l10n.parseNumber(formData.amount).toString(),
        //   })
        // )?.unwrap();
      }
      props.onClose();
    } catch {
      dispatch(
        notify({
          message: t("translation:technicalError"),
          type: "error",
        })
      );
    }
  };

  return (
    <Dialog open>
      <Formik
        validationSchema={validationSchema}
        initialValues={{
          percentage: line?.percentage || "",
        }}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, values, ...rest }) => {
          return (
            <Form>
              <DialogTitle>
                {t("invoicing:invoiceLine.editSurcharge")}
              </DialogTitle>
              <DialogContent dividers>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Field
                      component={MuiTextField}
                      label={t("invoicing:invoiceLine.surcharge")}
                      name="percentage"
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={onClose}>
                  {t("components:buttonLabels.cancel")}
                </Button>
                <Button type="submit" disabled={isSubmitting}>
                  {t("components:buttonLabels.save")}
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

export default EditSurchargeLineModal;
